import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getUsersRequest,
  createFacilityRequest,
  getFacilityRequest,
} from '../actions/userActions';
import {
  updateUserRequest,
  deleteFacilityRequest,
} from '../actions/userActions';
import {
  updateFacilityRequest,
  mergeFacilities,
  searchRequest,
  loadFacilityRequest,
} from '../actions/facilityActions';
import { facesheetRequest, updateFacesheets } from '../actions/reportActions';
import { getLoginsRequest } from '../actions/facilityLoginActions';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import '@rmwc/dialog/styles';
import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import '../css/FacilityComponent.css';
import { Button } from '@rmwc/button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { Select } from '@rmwc/select';
import { Typography } from '@rmwc/typography';
import Swal from 'sweetalert2';
import { CircularProgress } from '@rmwc/circular-progress';
import TablePagination from '@material-ui/core/TablePagination';

// import {
//   searchRequest,
// } from '../actions/resultsActions';

class FacilityComponent extends Component {
  constructor() {
    super();
    this.state = {
      facilities: [],
      webUserInfoDialog: false,
      facilityList: [],
      facilityState: '',
      currentFacility: [],
      editFacilityDialogOpen: false,
      facilitySort: null,
      sortDir: 'ASC',
      facilityInfoDialog: false,
      mergeFacilityDialogOpen: false,
      facilityList: [],
      mergeList: [],
      logins: [],
      facilityTotal: 100,
      facilityPage: 0,
      facilitiesPerPage: 10,
      offset: 0,
    };
  }
  static getDerivedStateFromProps(props, state) {
    let facilityList = [''];
    props.facilities.forEach((fac) => {
      facilityList.push(fac);
    });
    return {
      ...state,
      facilities: props.facilities,
      facilityList: facilityList,
      logins: props.logins,
      count: props.facilityTotal,
    };
  }

  componentDidMount() {
    this.props.getFacilities(
      this.state.facilitiesPerPage,
      this.state.offset,
      this.state.sortCol,
      this.state.sortDir
    );
    this.props.getLoginsRequest();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  }

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [event.target.name]: value }, () => {});
  };

  isEmpty(str) {
    return !str || str.length === 0;
  }

  addFacility = () => {
    let facID = this.state.facLoginID;
    if (this.isEmpty(facID)) {
      facID = 0;
    }
    let facilityJson = {
      facilityName: this.state.facilityName,
      facLoginID: facID,
      facilityAddress: this.state.facilityAddress,
      facilityCity: this.state.facilityCity,
      facilityState: this.state.facilityState,
      facilityZipcode: this.state.facilityZipcode,
      facilityPhoneNum: this.state.facilityPhoneNum,
      facilityUsername: this.state.facilityUsername,
    };
    this.props.createFacilityRequest(
      facilityJson,
      this.state.facilitiesPerPage,
      this.state.offset,
      this.state.sortCol,
      this.state.sortDir
    );
  };
  updateFacility = () => {
    let facilityJson = {
      facilityID: this.state.facilityID,
      facilityName: this.state.facilityName,
      facilityLoginID: this.state.facLoginID,
      facilityAddress: this.state.facilityAddress,
      facilityCity: this.state.facilityCity,
      facilityState: this.state.facilityState,
      facilityZipcode: this.state.facilityZipcode,
      facilityPhoneNum: this.state.facilityPhoneNum,
      facilityPhysicians: this.state.facilityPhysicians,
      facLoginID: this.state.facilityLoginID,
      facilityUsername: this.state.facilityUsername,
    };
    this.props.updateFacilityRequest(facilityJson);
  };

  sortData(sortDir) {
    const { facilities } = this.state;
    if (sortDir === '1') {
      this.setState({ sortDir: 'ASC' }, () => {
        facilities.sort((a, b) => {
          if (a['facilityName'] > b['facilityName']) {
            return 1;
          }
          if (a['facilityName'] < b['facilityName']) {
            return -1;
          }
          return 0;
        });
      });
    } else if (sortDir === -1) {
      facilities.reverse();
    } else {
      this.setState({ sortDir: 'DESC' }, () => {
        facilities.sort((a, b) => {
          if (a['facilityName'] > b['facilityName']) {
            return 1;
          }
          if (a['facilityName'] < b['facilityName']) {
            return -1;
          }
          return 0;
        });
      });
    }
    this.setState({ facilities: facilities });
  }
  clearSearch() {
    this.setState({
      noResults: false,
      filteredPatients: [],
      searchCol: '',
      query: '',
      offset: 0,
      facilityPage: 0,
      searching: false,
    });
    this.props.getFacilities(
      this.state.facilitiesPerPage,
      this.state.offset,
      this.state.sortCol,
      this.state.sortDir
    );
  }
  handleChangePage = (event, newPage) => {
    let offset = this.state.facilitiesPerPage * newPage;

    console.log(this.state.searching);
    console.log(this.state.searchCol);
    this.setState(
      {
        facilityPage: newPage,
        offset: offset,
      },
      () => {
        // let offset = this.state.facilitiesPerPage * this.state.facilityPage;

        if (this.state.searching) {
          this.props.searchResults(
            this.state.searchCol,
            this.state.query,
            this.state.facilitiesPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else {
          this.props.getFacilities(
            this.state.facilitiesPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      }
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        facilityPage: 0,
        facilitiesPerPage: parseInt(event.target.value),
        // patientFirstSort: 0,
        // patientLastSort: 1,
        // facilitySort: 0,
        // offset: 0,
      },
      () => {
        this.handleChangePage(null, this.state.facilityPage);
        if (this.state.searching) {
          this.props.searchResults(
            this.state.searchCol,
            this.state.query,
            this.state.facilitiesPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else {
          this.props.getFacilities(
            this.state.facilitiesPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      }
    );
  };

  render() {
    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];

    let facilityInfoDialog = () => {
      return (
        <Dialog
          open={this.state.facilityInfoDialog}
          onClose={(evt) => {
            this.setState({ facilityInfoDialog: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle id="addTitle">Facility Information</DialogTitle>

          <DialogContent>
            <Grid>
              <GridRow>
                <GridCell span={12}></GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Name: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentFacility.facilityName}
                </GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Address: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentFacility.facilityAddress === '' ||
                  this.state.currentFacility.facilityAddress === null
                    ? 'Unknown Address'
                    : this.state.currentFacility.facilityAddress +
                      ' ' +
                      this.state.currentFacility.facilityCity +
                      ' ' +
                      this.state.currentFacility.facilityState +
                      ' ' +
                      this.state.currentFacility.facilityZipcode}
                </GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Phone Number: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentFacility.facilityPhoneNum == null
                    ? 'Unknown Phone Number'
                    : this.state.currentFacility.facilityPhoneNum}
                </GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">EMR Login: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentFacility.facilityUsername == null
                    ? 'Unassociated Login'
                    : this.state.currentFacility.facilityUsername}
                </GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Scraper: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentFacility.scraper == null
                    ? 'Unknown Scraper'
                    : this.state.currentFacility.scraper}
                </GridCell>
              </GridRow>
              <hr></hr>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ facilityInfoDialog: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let mergeFacilityDialog = () => {
      let facilityList = this.state.facilityList;
      let mergeList = [];
      return (
        <Dialog
          open={this.state.mergeFacilityDialogOpen}
          onClose={(evt) => {
            this.setState({ mergeFacilityDialogOpen: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Merge Facilities
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Typography id="merge" use="headline10">
              Select 2 existing facilities to merge. The newly merged facility
              will take the name of Facility 1.
            </Typography>
            <Grid>
              <GridRow style={{ position: 'absolute', top: '25%' }}>
                <GridCell span={3}>Facility 1:</GridCell>
                <GridCell
                  span={9}
                  style={{ width: '60%', position: 'fixed', left: '15%' }}
                >
                  <Select
                    id="facilitySelect"
                    placeholder="Facility 1"
                    outlined
                    native
                    displayEmpty
                    value={this.state.fac1}
                    onChange={(event) =>
                      this.setState({ fac1: event.currentTarget.value })
                    }
                    labelId="Facility"
                    inputProps={{
                      name: 'fac1',
                    }}
                  >
                    {facilityList.map((state) => (
                      <option key={state.facilityID} value={state.facilityID}>
                        {state.facilityName}
                      </option>
                    ))}
                  </Select>
                  <div style={{ paddingBottom: '10px' }}></div>
                </GridCell>
              </GridRow>
              <GridRow style={{ position: 'absolute', top: '40%' }}>
                <GridCell span={3}>Facility 2:</GridCell>
                <GridCell
                  span={9}
                  style={{ width: '60%', position: 'fixed', left: '15%' }}
                >
                  <Select
                    id="facilitySelect"
                    placeholder="Facility 2"
                    outlined
                    native
                    displayEmpty
                    onChange={(event) =>
                      this.setState({ fac2: event.currentTarget.value })
                    }
                    labelId="Facility"
                    value={this.state.fac2}
                    inputProps={{
                      name: 'fac2',
                    }}
                  >
                    {facilityList.map((state) => (
                      <option key={state.facilityID} value={state.facilityID}>
                        {state.facilityName}
                      </option>
                    ))}
                  </Select>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Button
              action="add"
              icon="add"
              unelevated
              onClick={() =>
                Swal.fire({
                  title: 'Are you sure you want to merge these Facilities ?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, merge these facilities!',
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.props.mergeFacilities(
                      this.state.fac1,
                      this.state.fac2
                    );
                    Swal.fire(
                      'Merged!',
                      'The facilities have been merged.',
                      'success'
                    );
                    this.setState({
                      mergeFacilityDialogOpen: false,
                      fac1: [],
                      fac2: [],
                    });
                  } else {
                    Swal.fire(
                      'Canceled!',
                      'The facilities have not been merged.',
                      'warning'
                    );
                  }
                })
              }
            >
              Merge
            </Button>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  mergeFacilityDialogOpen: false,
                  fac1: [],
                  fac2: [],
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let editFacilityDialog = () => {
      return (
        <Dialog
          open={this.state.editFacilityDialogOpen}
          onClose={(evt) => {
            this.setState({ editFacilityDialogOpen: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Modify Facility
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Facility Name:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    required
                    style={{ width: '500px' }}
                    placeholder="Facility Name"
                    outlined
                    name="facilityName"
                    value={this.state.facilityName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Associated EMR:</Typography>
                </GridCell>
                <GridCell span={9} style={{ width: '75%' }}>
                  <Select
                    value={this.state.facJsonString}
                    outlined
                    onChange={(event) => {
                      this.setState({
                        facJsonString: event.currentTarget.value,
                        facLoginID: JSON.parse(event.currentTarget.value)
                          .facLoginID,
                        facilityUsername: JSON.parse(event.currentTarget.value)
                          .facilityUsername,
                      });
                    }}
                  >
                    {this.state.logins.map((state) => {
                      return (
                        <option value={JSON.stringify(state)}>
                          {state.facilityUsername}
                        </option>
                      );
                    })}
                  </Select>
                  <div style={{ paddingBottom: '10px' }}></div>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Address:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    outlined
                    placeholder="Address"
                    name="facilityAddress"
                    value={this.state.facilityAddress}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>City/State/Zip:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '250px' }}
                    outlined
                    placeholder="City"
                    name="facilityCity"
                    value={this.state.facilityCity}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell
                  span={3}
                  style={{ left: '-20%', position: 'relative', width: '235px' }}
                >
                  <Select
                    outlined
                    native
                    displayEmpty
                    value={this.state.facilityState}
                    onChange={(event) =>
                      this.setState({
                        facilityState: event.currentTarget.value,
                      })
                    }
                    placeholder="State"
                    inputProps={{
                      name: 'facilityState',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}></GridCell>
                <GridCell span={9}>
                  <GridCell span={3}>
                    <TextField
                      style={{ width: '166px' }}
                      outlined
                      placeholder="Zipcode"
                      name="facilityZipcode"
                      value={this.state.facilityZipcode}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Phone Number:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    placeholder="Phone Number"
                    outlined
                    name="facilityPhoneNum"
                    value={this.state.facilityPhoneNum}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              action="update"
              unelevated
              icon="save"
              onClick={(evt) => {
                if (this.isEmpty(this.state.facilityName.trim())) {
                  evt.stopPropagation();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'you must fill out the required fields',
                  });
                } else {
                  this.updateFacility();
                }
              }}
            >
              Update
            </DialogButton>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({ editFacilityDialogOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let addFacilityDialog = () => {
      return (
        <Dialog
          open={this.state.addFacilityDialogOpen}
          onClose={(evt) => {
            this.setState({ addFacilityDialogOpen: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Add Facility
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Facility Name:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    required
                    style={{ width: '500px' }}
                    placeholder="Facility Name"
                    outlined
                    name="facilityName"
                    value={this.state.facilityName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Associated EMR:</Typography>
                </GridCell>
                <GridCell span={9} style={{ width: '75%' }}>
                  <Select
                    id="loginsSelect"
                    outlined
                    native
                    displayEmpty
                    onChange={(event) =>
                      this.setState({ facLoginID: event.currentTarget.value })
                    }
                    placeholder="Associated EMR Login"
                    value={this.state.facLoginID}
                    inputProps={{
                      name: 'facLoginID',
                    }}
                  >
                    {this.state.logins.map((state) => (
                      <option key={state.facLoginID} value={state.facLoginID}>
                        {state.facilityUsername}
                      </option>
                    ))}
                  </Select>
                  <div style={{ paddingBottom: '10px' }}></div>
                </GridCell>
              </GridRow>

              <GridRow>
                <GridCell span={3}>
                  <Typography>Address:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    outlined
                    placeholder="Address "
                    name="facilityAddress"
                    value={this.state.facilityAddress}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>City/State/Zip:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '250px' }}
                    outlined
                    placeholder="City "
                    name="facilityCity"
                    value={this.state.facilityCity}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell
                  span={3}
                  style={{ left: '-20%', position: 'relative', width: '235px' }}
                >
                  <Select
                    outlined
                    native
                    displayEmpty
                    value={this.state.facilityState}
                    onChange={(event) =>
                      this.setState({
                        facilityState: event.currentTarget.value,
                      })
                    }
                    placeholder="Facility State"
                    inputProps={{
                      name: 'facilityState',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Phone Number:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    placeholder="Facility Phone Number"
                    outlined
                    name="facilityPhoneNum"
                    value={this.state.facilityPhoneNum}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              action="add"
              icon="add"
              unelevated
              onClick={(evt) => {
                if (this.isEmpty(this.state.facilityName)) {
                  evt.stopPropagation();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'you must fill out the required fields',
                  });
                } else {
                  this.addFacility();
                }
              }}
            >
              Add
            </DialogButton>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  addFacilityDialogOpen: false,
                  facilityID: '',
                  facilityName: '',
                  facLoginID: '',
                  facilityAddress: '',
                  facilityCity: '',
                  facilityState: '',
                  facilityZipcode: '',
                  facilityPhoneNum: '',
                  facilityPhysicians: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let facilitiesData = () =>
      this.state.facilities.map((facility, i) => {
        return (
          <DataTableRow key={i}>
            <DataTableCell
              style={{ backgroundColor: '#DBF1FF' }}
              onClick={() => {
                this.setState({
                  facilityInfoDialog: true,
                  currentFacility: facility,
                });
              }}
            >
              {facility.facilityName}
            </DataTableCell>
            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
              <Button
                disabled={this.props.role !== 'Tsalta Admin'}
                icon="edit"
                onClick={() => {
                  let login = {
                    facLoginID: facility.facilityLoginID,
                    facilityUsername: facility.facilityUsername,
                    facilityPassword: facility.facilityPassword,
                    link: facility.link,
                    scraper: facility.scraper,
                  };
                  this.setState({
                    editFacilityDialogOpen: true,
                    currentFacility: facility,
                    facJsonString: JSON.stringify(login),
                    facilityID: facility.facilityID,
                    facilityName: facility.facilityName,
                    facLoginID: facility.facilityLoginID,
                    facilityAddress: facility.facilityAddress,
                    facilityCity: facility.facilityCity,
                    facilityState: facility.facilityState,
                    facilityZipcode: facility.facilityZipcode,
                    facilityPhoneNum: facility.facilityPhoneNum,
                    facilityPhysicians: facility.facilityPhysicians,
                    facilityUsername: facility.facilityUsername,
                  });
                }}
              >
                Modify
              </Button>
            </DataTableCell>
            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
              <Button
                disabled={this.props.role !== 'Tsalta Admin'}
                icon="update"
                onClick={() => {
                  Swal.fire({
                    title:
                      'Would you like to update the facesheets for "' +
                      facility.facilityName +
                      '" ?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, update facesheets!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.props.updateFacesheets(facility, 'facility');
                      Swal.fire(
                        'Updating!',
                        'Your facesheets are being updated.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Update
              </Button>
              <Button
                icon="print"
                disabled={this.props.genFacesheetLoading}
                onClick={() => {
                  this.props.facesheetRequest(facility.facilityName);
                }}
              >
                Print
              </Button>
            </DataTableCell>
            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
              <Button
                disabled={this.props.role !== 'Tsalta Admin'}
                icon="delete"
                onClick={() => {
                  Swal.fire({
                    title:
                      'Are you sure you want to delete Facility ' +
                      facility.facilityName +
                      ' ?',
                    text: 'You will not be able to revert this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.props.deleteFacility(
                        facility.facilityID,
                        this.state.facilitiesPerPage,
                        this.state.offset,
                        this.state.sortCol,
                        this.state.sortDir
                      );
                      Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Delete
              </Button>
              {this.props.genFacesheetLoading ? (
                <CircularProgress id="genFacesheet"></CircularProgress>
              ) : null}
            </DataTableCell>
          </DataTableRow>
        );
      });

    return (
      <div>
        <Grid>
          <GridRow>
            <GridCell span={5}></GridCell>
            <GridRow>
              <GridCell
                span={3}
                style={{ position: 'absolute', left: '48%', width: '20%' }}
              >
                <div id="facSearchCol">
                  <Select
                    outlined
                    label="Field"
                    options={['Facility Name', 'State']}
                    value={this.state.searchCol}
                    onChange={(event) => {
                      this.setState({
                        searchCol: event.currentTarget.value,
                        query: '',
                      });
                    }}
                  />
                </div>
              </GridCell>
              <GridCell
                span={9}
                style={{ position: 'absolute', left: '69%', width: '22%' }}
              >
                {this.state.searchCol === 'Facility Name' ? (
                  <Select
                    label="Facility Search"
                    outlined
                    value={this.state.query}
                    onChange={(event) => {
                      this.setState({
                        query: event.currentTarget.value,
                      });
                    }}
                  >
                    {this.state.facilityList.map((facility) => {
                      return (
                        <option
                          value={facility.facilityName}
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {facility.facilityName}
                        </option>
                      );
                    })}
                  </Select>
                ) : this.state.searchCol === 'State' ? (
                  <Select
                    label="State"
                    outlined
                    native
                    displayEmpty
                    value={this.state.query}
                    onChange={(event) =>
                      this.setState({
                        query: event.currentTarget.value,
                      })
                    }
                    placeholder="State"
                    inputProps={{
                      name: 'facilityState',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    label="Search"
                    outlined
                    value={this.state.query}
                    onChange={(event) => {
                      this.setState({
                        query: event.currentTarget.value,
                      });
                    }}
                  ></Select>
                )}
              </GridCell>

              <GridCell span={3}>
                <Button
                  style={{ position: 'absolute', top: '4%', left: '91%' }}
                  id="facilitySearchButton"
                  icon="search"
                  onClick={() => {
                    this.setState({ searching: true }, () => {
                      this.props.searchResults(
                        this.state.searchCol,
                        this.state.query,
                        this.state.facilitiesPerPage,
                        this.state.offset,
                        this.state.sortCol,
                        'ASC'
                      );
                    });
                  }}
                ></Button>
              </GridCell>
            </GridRow>

            {/* <GridCell span={3}>
              <div id="patientSearchCol">
                <Select
                  outlined
                  label="Field"
                  options={['Patient Last Name', 'Facility']}
                  value={this.state.searchCol}
                  onChange={(event) => {
                    this.setState({
                      searchCol: event.currentTarget.value,
                      query: '',
                    });
                  }}
                />
              </div>
              {this.state.filteredPatients.length !== 0 ? (
                <Button id="clearResultsBut" onClick={() => this.clearSearch()}>
                  {' '}
                  clear results
                </Button>
              ) : null}
            </GridCell> */}
            {/* <GridCell span={3}>
              {this.state.searchCol === 'Facility' ? (
                <div>{searchField()}</div>
              ) :
               (
                <form onSubmit={this.handleSubmit}>
                  <TextField
                    style={{ width: '100%' }}
                    outlined
                    trailingIcon={{
                      icon: 'search',
                      tabIndex: 0,
                      onClick: () => {
                        this.setState({ searching: true, offset: 0 }, () => {
                          this.props.searchResults(
                            this.state.searchCol,
                            this.state.query,
                            this.state.patientsPerPage,
                            this.state.offset,
                            this.state.sortCol,
                            this.state.sortDir
                          );
                        });
                      },
                    }}
                    name="query"
                    value={this.state.query}
                    label="Search Term"
                    onChange={this.handleChange}
                    onKeyDown={this.keyPress}
                  />
                </form>
              )
              }
            </GridCell> */}
          </GridRow>
          <GridRow>
            <GridCell span={9}>
              <div id="patientHead">
                <Typography use="headline4">Facilities</Typography>
              </div>
            </GridCell>
            <GridCell span={3}>
              <Button
                style={{ left: '79%', width: '15%' }}
                id="clearSearch"
                icon="clear"
                label="Clear Search"
                onClick={() => this.clearSearch()}
              ></Button>
            </GridCell>
          </GridRow>
        </Grid>
        {/* <div id="facilityHead">
          <div id="facility">
            <Typography use="headline4"> Facilities </Typography>
          </div>
        </div> */}
        <DataTable id="facilityTable" stickyRows={1} stickyColumns={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell
                  value={this.state.facilitySort}
                  sort={this.state.facilitySort}
                  onSortChange={(sortDir) => {
                    this.setState(
                      {
                        facilitySort: sortDir,
                      },
                      () => {
                        this.sortData(sortDir);
                      }
                    );
                  }}
                >
                  Facility Name
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Actions
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Facesheets
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Delete
                </DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{facilitiesData()}</DataTableBody>
          </DataTableContent>
        </DataTable>
        <TablePagination
          id="patientPag"
          component="div"
          count={this.state.count}
          page={this.state.facilityPage}
          onPageChange={this.handleChangePage}
          rowsPerPage={this.state.facilitiesPerPage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100, 2500]}
        ></TablePagination>
        <div>
          <div>
            <Button
              id="addButton"
              variant="contained"
              disabled={this.props.role !== 'Tsalta Admin'}
              onClick={() =>
                this.setState({
                  addFacilityDialogOpen: true,
                  facilityID: '',
                  facilityName: '',
                  facLoginID: '',
                  facilityAddress: '',
                  facilityCity: '',
                  facilityState: '',
                  facilityZipcode: '',
                  facilityPhoneNum: '',
                  facilityPhysicians: '',
                })
              }
            >
              Add Facility
            </Button>
          </div>
          <div>
            <Button
              id="mergeButton"
              variant="contained"
              disabled={this.props.role !== 'Tsalta Admin'}
              onClick={() => this.setState({ mergeFacilityDialogOpen: true })}
            >
              Merge Facilities
            </Button>
          </div>
        </div>
        {/* </TableContainer> */}
        {addFacilityDialog()}
        {editFacilityDialog()}
        {facilityInfoDialog()}
        {mergeFacilityDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.login.decodedJWT.role,
  facilities: state.facilities.facilities,
  logins: state.facilityLogins.logins,
  genFacesheetLoading: state.report.genFacesheetLoading,
  facilityTotal: state.facilities.facilityTotal,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsersRequest()),
    getFacilities: (limit, offset, sort, dir) =>
      dispatch(loadFacilityRequest(limit, offset, sort, dir)),
    updateUser: () => dispatch(updateUserRequest()),
    createFacilityRequest: (json, limit, offset, sort, dir) =>
      dispatch(createFacilityRequest(json, limit, offset, sort, dir)),
    deleteFacility: (facilityID, limit, offset, sort, dir) =>
      dispatch(deleteFacilityRequest(facilityID, limit, offset, sort, dir)),
    updateFacilityRequest: (json) => dispatch(updateFacilityRequest(json)),
    mergeFacilities: (fac1, fac2) => dispatch(mergeFacilities(fac1, fac2)),
    getLoginsRequest: () => dispatch(getLoginsRequest()),
    facesheetRequest: (where) => dispatch(facesheetRequest(where)),
    updateFacesheets: (where, type) => dispatch(updateFacesheets(where, type)),
    searchResults: (col, term, limit, offset, sort, dir) =>
      dispatch(searchRequest(col, term, limit, offset, sort, dir)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FacilityComponent);
