import {
  UPDATE_FACILITY_FAIL,
  UPDATE_FACILITY_SUCCESS,
  FACILITY_SEARCH_SUCCESS,
  ADMIN_FACILITYLIST_SUCCESS,
} from '../constants/facilityConstants';
const initialState = {
  facilities: [],
  facilityTotal: 0
};
const facilityReducer = (state = initialState, action) => {

  switch (action.type) {
    case FACILITY_SEARCH_SUCCESS:
      return {
        ...state,
        facilities: action.facilityResults,
        facilityTotal: action.facilityTotal,
      };
    case ADMIN_FACILITYLIST_SUCCESS:
      return {
        ...state,
        facilities: action.facilityList,
        facilityTotal: action.facilityTotal
      };
    default:
      return state;
  }

};
export default facilityReducer;
