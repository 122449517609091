import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Typography } from '@rmwc/typography';

class GeneralTabComponent extends Component {
  componentDidMount() {

  }
  render() {
    var patientDOB = this.props.patient.patientDOB;
    var hrDate = new Date(patientDOB);

    return (
      <div>
        <Grid>
          <GridRow>
            <GridCell span={12}></GridCell>
          </GridRow>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Full Name: </Typography>
            </GridCell>
            <GridCell span={6}>
              {this.props.patient.patientFirstName +
                ' ' +
                this.props.patient.patientLastName}
            </GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Sex: </Typography>
            </GridCell>
            <GridCell span={6}>{this.props.patient.patientSex}</GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Date of birth: </Typography>
            </GridCell>
            <GridCell span={6}>
              {hrDate === null
                ? 'Unknown'
                : hrDate.getMonth() +
                  1 +
                  '/' +
                  hrDate.getDate() +
                  '/' +
                  hrDate.getFullYear()}
            </GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Facility: </Typography>
            </GridCell>
            <GridCell span={6}>{this.props.patient.facilityName}</GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Doctor Name: </Typography>
            </GridCell>
            <GridCell span={6}>
              {this.props.patient.doctorFirstName +
                ' ' +
                this.props.patient.doctorLastName}
            </GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Doctor NPI: </Typography>
            </GridCell>
            <GridCell span={6}>{this.props.patient.doctorNPI}</GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Room Number: </Typography>
            </GridCell>
            <GridCell span={6}>
              {this.props.patient.patientRoomNum === 0
                ? 'No Room Assignment'
                : this.props.patient.patientRoomNum}
            </GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Payer Name: </Typography>
            </GridCell>
            <GridCell span={6}>{this.props.patient.payerName === null ? 'No Information' : this.props.patient.payerName}</GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Group ID: </Typography>
            </GridCell>
            <GridCell span={6}>{this.props.patient.groupID === null ? 'No Information' : this.props.patient.payerName}</GridCell>
          </GridRow>
          <hr></hr>
          <GridRow>
            <GridCell span={6}>
              <Typography id="textBold">Policy ID: </Typography>
            </GridCell>
            <GridCell span={6}>{this.props.patient.policyID === null ? 'No Information' : this.props.patient.policyID}</GridCell>
          </GridRow>
          <hr></hr>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralTabComponent);
