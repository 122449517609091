import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import { TextField } from '@rmwc/textfield';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import { Select } from '@rmwc/select';
import { Typography } from '@rmwc/typography';
import { getFacilityRequest } from '../actions/userActions';
import {
  getPhysiciansRequest,
  createPhysicianRequest,
  deletePhysicianRequest,
  updatePhysicianRequest,
} from '../actions/physicianActions';
import '../css/PhysicianComponent.css';
import Swal from 'sweetalert2';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Paper, ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import List from '@material-ui/core/List';
import { Checkbox } from 'rmwc';

class physicianComponent extends Component {
  constructor() {
    super();
    this.state = {
      physicians: [],
      facilities: [],
      addphysicianDialogOpen: false,
      updatePhysican: false,
      currentPhysician: [],
      sortCol: '',
      physicianLastSort: null,
      sortDir: 'ASC',
      rightList: [],
      leftList: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      physicians: props.physicians,
      facilities: props.facilities,
    };
  }

  componentDidMount() {
    this.props.getFacilities();
    this.props.getphysicians();
  }
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('check*');

      if (regex.test(key)) {
        console.log(key);
        this.setState({ [key]: false });
      }
      return null;
    });
  };
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  }
  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [event.target.name]: value }, () => {});
  };

  addphysician = () => {
    let physicianJson = {
      physicianNPI: this.state.physicianNPI,
      physicianFirstName: this.state.physicianFirstName,
      physicianLastName: this.state.physicianLastName,
      physicianAddress: this.state.physicianAddress,
      physicianCity: this.state.physicanCity,
      physicianState: this.state.physicianState,
      physicianZip: this.state.physicianZip,
      physicianFacility: this.state.physicianFacility,
    };
    this.props.createPhysican(physicianJson);
  };
  isEmpty(str) {
    return !str || str.length === 0;
  }

  updatephysician = () => {
    let markedCheckbox = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let checked = [];
    for (var checkbox of markedCheckbox) {
      checked.push(checkbox.value);
    }

    let physicianJson = {
      doctorNPI: this.state.physicianNPI,
      doctorFirstName: this.state.physicianFirstName,
      doctorLastName: this.state.physicianLastName,
      doctorAddress: this.state.physicianAddress,
      doctorCity: this.state.physicianCity,
      doctorState: this.state.physicianState,
      doctorZipcode: this.state.physicianZip,
      doctorFacility: checked,
    };
    this.props.updatePhysican(physicianJson);
  };

  sortData(sortDir) {
    const { physicians } = this.state;
    if (sortDir === '1') {
      this.setState({ sortDir: 'ASC' }, () => {
        physicians.sort((a, b) => {
          if (a['doctorLastName'] > b['doctorLastName']) {
            return 1;
          }
          if (a['doctorLastName'] < b['doctorLastName']) {
            return -1;
          }
          return 0;
        });
      });
    } else if (sortDir === -1) {
      physicians.reverse();
    } else {
      this.setState({ sortDir: 'DESC' }, () => {
        physicians.sort((a, b) => {
          if (a['doctorLastName'] > b['doctorLastName']) {
            return 1;
          }
          if (a['doctorLastName'] < b['doctorLastName']) {
            return -1;
          }
          return 0;
        });
      });
    }
    this.setState({ physicians: physicians });
  }
  render() {
    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];
    let physicianInfoDialog = () => {
      let facilityList = [];
      let fac = '';
      console.log("doctor")
      console.log(this.state.currentPhysician)
      if (this.state.currentPhysician.doctorFacility !== undefined) {
        fac = JSON.parse(this.state.currentPhysician.doctorFacility);
      }
      if (Object.keys(this.state.currentPhysician).length !== 0) {
        let count = 0;
        if (
          this.state.currentPhysician.doctorFacility !== null &&
          this.state.currentPhysician.doctorFacility !== undefined
        ) {
          fac.forEach((facility) => {
            let temp = JSON.parse(facility);
            if (fac.length - 1 === count) {
              facilityList.push(temp.facilityName);
            } else {
              facilityList.push(temp.facilityName + ', ');
            }
            count++;
          });
        }
      }
      return (
        <Dialog
          open={this.state.physicianInfoDialog}
          onClose={(evt) => {
            this.setState({ physicianInfoDialog: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle id="addTitle">Physician Information</DialogTitle>
          <DialogContent>
            <Grid>
              <GridRow>
                <GridCell span={12}></GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Name: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentPhysician.doctorFirstName +
                    ' ' +
                    this.state.currentPhysician.doctorLastName}
                </GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Facilities: </Typography>
                </GridCell>

                <GridCell style={{ width: '150%' }}>{facilityList}</GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Address: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentPhysician.doctorAddress == null
                    ? 'Unknown Address'
                    : this.state.currentPhysician.doctorAddress +
                      ' ' +
                      this.state.currentPhysician.doctorCity +
                      ' ' +
                      this.state.currentPhysician.doctorCity +
                      ' ' +
                      this.state.currentPhysician.doctorZipcode}
                </GridCell>
              </GridRow>
              <hr></hr>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ physicianInfoDialog: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let addFacilityDialog = () => {
      return (
        <Dialog
          open={this.state.addphysicianDialogOpen}
          onOpen={() => {
            console.log(this.state.physicianFacility);
            let leftList = this.state.facilities;
            if (this.state.physicianFacility) {
              this.state.physicianFacility.map((fac) => {
                let fac1 = JSON.parse(fac);

                let checkname = 'check' + fac1.facilityID;
                console.log(checkname);

                this.setState({ [checkname]: true });
              });
              this.setState({ leftList: leftList });
            }
          }}
          onClose={(evt) => {
            this.clearChecks();
            this.setState({
              addphysicianDialogOpen: false,
              updatePhysican: false,
              physicianNPI: '',
              physicianFirstName: '',
              physicianLastName: '',
              physicianAddress: '',
              physicianCity: '',
              physicianState: '',
              physicianZip: '',
              physicianFacility: [],
            });
          }}
          onClosed={(evt) => {
            this.clearChecks();
          }}
        >
          {!this.state.updatePhysican ? (
            <DialogTitle
              id="addTitle"
              style={{
                borderTop: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              Add Physician
            </DialogTitle>
          ) : (
            <DialogTitle
              id="addTitle"
              style={{
                borderTop: 'solid',
                borderLeft: 'solid',
                borderRight: 'solid',
                borderColor: 'dodgerblue',
              }}
            >
              Modify Physician
            </DialogTitle>
          )}

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <div>
              <Grid id="addGrid">
                <GridRow>
                  <GridCell span={3}>
                    <Typography>First Name:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <TextField
                      style={{ width: '500px' }}
                      id="name"
                      outlined
                      required
                      placeholder="physician First Name"
                      name="physicianFirstName"
                      value={this.state.physicianFirstName}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>Last Name:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <TextField
                      style={{ width: '500px' }}
                      outlined
                      required
                      placeholder="physician Last Name"
                      name="physicianLastName"
                      value={this.state.physicianLastName}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>NPI:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <TextField
                      style={{ width: '500px' }}
                      outlined
                      required
                      placeholder="physician NPI "
                      name="physicianNPI"
                      disabled={this.state.updatePhysican}
                      value={this.state.physicianNPI}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>Address:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <TextField
                      style={{ width: '500px' }}
                      outlined
                      placeholder="Address "
                      name="physicianAddress"
                      value={this.state.physicianAddress === null ? '' : this.state.physicianAddress}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>City/State/Zip:</Typography>
                  </GridCell>
                  <GridCell span={4}>
                    <TextField
                      style={{ width: '300px' }}
                      outlined
                      placeholder="City "
                      name="physicianCity"
                      value={this.state.physicianCity === null ? '' : this.state.physicianCity}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                  <GridCell span={3}>
                    <Select
                      outlined
                      native
                      displayEmpty
                      value={this.state.physicianState}
                      onChange={(event) =>
                        this.setState({
                          physicianState: event.currentTarget.value,
                        })
                      }
                      placeholder="State"
                      inputProps={{
                        name: 'physicianState',
                      }}
                    >
                      {states.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}></GridCell>
                  <GridCell span={9}>
                    <GridCell span={3}>
                      <TextField
                        style={{ width: '166px' }}
                        outlined
                        placeholder="Zipcode"
                        name="physicianZip"
                        value={this.state.physicianZip === null ? '' : this.state.physicianZip}
                        onChange={(e) => this.handleChange(e)}
                      ></TextField>
                    </GridCell>
                  </GridCell>
                </GridRow>

                <GridRow>
                  <GridCell span={3}>
                    <Typography>Facilities: </Typography>
                    <br></br>
                  </GridCell>
                  <GridCell span={9}>
                    <Grid id="lGrid" item>
                      <Paper id="lPaper" variant="outlined">
                        <List dense component="div" role="list">
                          {this.state.facilities != null
                            ? this.state.facilities.map((facility) => {
                                let checkname = 'check' + facility.facilityID;
                                return (
                                  <ListItem role="listitem">
                                    <ListItemIcon>
                                      <Checkbox
                                        name={facility.facilityID}
                                        tabIndex={-1}
                                        disableRipple
                                        checked={this.state[checkname]}
                                        value={JSON.stringify(facility)}
                                        onClick={(evt) => {}}
                                        onChange={(evt) => {
                                          this.setState({
                                            [checkname]:
                                              evt.currentTarget.checked,
                                          });
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={facility.facilityName}
                                    />
                                  </ListItem>
                                );
                              })
                            : null}
                          <ListItem />
                        </List>
                      </Paper>
                    </Grid>
                  </GridCell>
                </GridRow>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            {!this.state.updatePhysican ? (
              <DialogButton
                action="add"
                icon="add"
                unelevated
                onClick={(evt) => {
                  if (
                    this.isEmpty(this.state.physicianNPI) ||
                    this.isEmpty(this.state.physicianFirstName.trim()) ||
                    this.isEmpty(this.state.physicianLastName.trim())
                  ) {
                    evt.stopPropagation();
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'you must fill out the required fields',
                    });
                  } else {
                    this.addphysician();
                  }
                }}
              >
                Add
              </DialogButton>
            ) : (
              <DialogButton
                action="add"
                icon="add"
                unelevated
                onClick={(evt) => {
                  if (
                    this.isEmpty(this.state.physicianNPI) ||
                    this.isEmpty(this.state.physicianFirstName.trim()) ||
                    this.isEmpty(this.state.physicianLastName.trim())
                  ) {
                    evt.stopPropagation();
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'you must fill out the required fields',
                    });
                  } else {
                    this.updatephysician();
                  }
                }}
              >
                Update
              </DialogButton>
            )}
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({ addphysicianDialogOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let physiciansData = () =>
      this.state.physicians.map((physician, i) => {
        if (
          physician.doctorFacility !== undefined &&
          physician.doctorFacility !== null
        ) {
        }
        return (
          <DataTableRow key={i}>
            <DataTableCell
              style={{ backgroundColor: '#DBF1FF' }}
              onClick={() => {
                this.setState({
                  physicianInfoDialog: true,
                  currentPhysician: physician,
                });
              }}
            >
              {physician.doctorLastName + ', ' + physician.doctorFirstName}
            </DataTableCell>
            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
              {physician.doctorNPI}
            </DataTableCell>
            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
              <Button
                disabled={this.props.decodedJwt.role !== 'Tsalta Admin'}
                icon="edit"
                onClick={() => {
                  let facString = '';
                  try {
                    facString = JSON.parse(physician.doctorFacility);
                  } catch (error) {}
                  this.setState({
                    addphysicianDialogOpen: true,
                    updatePhysican: true,
                    physicianNPI: physician.doctorNPI,
                    physicianFirstName: physician.doctorFirstName,
                    physicianLastName: physician.doctorLastName,
                    physicianAddress: physician.doctorAddress,
                    physicianCity: physician.doctorCity,
                    physicianState: physician.doctorState,
                    physicianZip: physician.doctorZipcode,
                    physicianFacility: facString,
                  });
                }}
              >
                Modify
              </Button>
            </DataTableCell>
            <DataTableCell style={{ backgroundColor: '#DBF1FF' }}>
              <Button
                disabled={this.props.decodedJwt.role !== 'Tsalta Admin'}
                icon="delete"
                onClick={() => {
                  Swal.fire({
                    title:
                      'Are you sure you want to delete physician ' +
                      physician.doctorFirstName +
                      ' ' +
                      physician.doctorLastName +
                      ' NPI:' +
                      physician.doctorNPI +
                      ' ?',
                    text: 'You will not be able to revert this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.props.deletePhysician(physician.doctorNPI);
                      Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Delete
              </Button>
            </DataTableCell>
          </DataTableRow>
        );
      });
    return (
      <div>
        <div id="physicianHead">
          <div id="physicians">
            <Typography use="headline4"> Physicians </Typography>
          </div>
        </div>

        <DataTable id="physicianTable" stickyRows={1} stickyColumns={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                  value={this.state.physicianLastSort}
                  sort={this.state.physicianLastSort}
                  onSortChange={(sortDir) => {
                    this.setState(
                      {
                        physicianLastSort: sortDir,
                      },
                      () => {
                        this.sortData(sortDir);
                      }
                    );
                  }}
                >
                  Physician Name
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  NPI
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Actions
                </DataTableHeadCell>
                <DataTableCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Delete
                </DataTableCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{physiciansData()}</DataTableBody>
          </DataTableContent>
        </DataTable>
        <div>
          {this.props.decodedJwt.role !== 'Tsalta Admin' ? (
            <Button
              id="disabledButton"
              variant="contained"
              disabled
              onClick={() => this.setState({ addphysicianDialogOpen: true })}
            >
              Add physician
            </Button>
          ) : (
            <Button
              id="addButton"
              variant="contained"
              onClick={() => this.setState({ addphysicianDialogOpen: true })}
            >
              Add physician
            </Button>
          )}
        </div>
        {addFacilityDialog()}
        {physicianInfoDialog()}
        {}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    deletePhysician: (doctorNPI) => dispatch(deletePhysicianRequest(doctorNPI)),
    getFacilities: () => dispatch(getFacilityRequest()),
    getphysicians: () => dispatch(getPhysiciansRequest()),
    createPhysican: (json) => dispatch(createPhysicianRequest(json)),
    updatePhysican: (json) => dispatch(updatePhysicianRequest(json)),
  };
};

const mapStateToProps = (state) => ({
  physicians: state.physician.physicians,
  facilities: state.admin.facilities,
  decodedJwt: state.login.decodedJWT,
});

export default connect(mapStateToProps, mapDispatchToProps)(physicianComponent);
