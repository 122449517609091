import Swal from 'sweetalert2';
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_USER_INFO_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  VERIFY_PASS_REQUEST,
  VERIFY_PASS_FAILURE,
  VERIFY_PASS_SUCCESS,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_FAILURE,
  LOGIN_DBS_SUCCESS,
} from '../constants/loginConstants';
// import { OPEN_DIALOG_WITH_ARGS } from '../constants/dialogConstants';

const initialState = {
  jwt: null,
  username: '',
  success: true,
  decodedJWT: { role: '' },
  dbList: [],
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    // case OPEN_DIALOG_WITH_ARGS:
    //   return {
    //     ...state,
    //     verifyPassSuccess: false
    //   };
    case LOGIN_SUCCESS:
      return {
        ...state,
        jwt: action.jwt,
        decodedJWT: action.decodedJWT,
      };
    case UPDATE_USER_INFO_REQUEST:
      return state;
    case UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        decodedJWT: action.decodedJWT,
      };
    case UPDATE_USER_INFO_FAILURE:
      return state;
    case LOGIN_FAILURE:
      let error;
      if (action.error === 'Software needs to be activated') {
        error = 'activation';
      }

      Swal.fire({
        title: 'Invalid Login Attempt',
        icon: 'error',
        text: action.error,
      });

      return {
        ...state,
        success: false,
        error: error,
      };
    case LOGIN_DBS_SUCCESS:
      return {
        ...state,
        dbList: action.dbList,
      };
    case VERIFY_PASS_REQUEST:
      return {
        ...state,
        verifyPassError: null,
      };
    case VERIFY_PASS_FAILURE:
      return {
        ...state,
        verifyPassError: action.error.message,
      };
    case VERIFY_PASS_SUCCESS:
      return {
        ...state,
        verifyPassError: null,
        verifyPassSuccess: true,
      };
    case CHANGE_PASS_REQUEST:
      return {
        ...state,
      };
    case CHANGE_PASS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_PASS_FAILURE:
      return {
        ...state,
      };
    case LOGOUT:
      return initialState;
    case LOGIN_REQUEST:
    default:
      return {
        ...state,
        success: true,
      };
  }
};

export default loginReducer;
