import {
  ADMIN_QUERY_WEBUSERS_FAIL,
  ADMIN_QUERY_WEBUSERS_SUCCESS,
  ADMIN_ADD_USER_FAIL,
  ADMIN_ADD_USER_SUCCESS,
  ADMIN_DELETE_USER_FAIL,
  ADMIN_DELETE_USER_SUCCESS,
  ADMIN_DELETE_FACILITY_FAIL,
  ADMIN_DELETE_FACILITY_SUCCESS,
  ADMIN_FACILITYLIST_SUCCESS,
  ADMIN_FACILITYLIST_FAIL,
  ADMIN_AVAILFACILITYLIST_SUCCESS,
  ADMIN_AVAILFACILITYLIST_FAIL,
  QUERY_CURRENT_WEBUSER_SUCCESS,
  PROFILE_LOADING,
  PROFILE_LOADING_FAIL,
  PROFILE_LOADING_SUCCESS,
  PROFILE_UPDATE_PASSWORD_FAIL,
  QUERY_WATCHLIST_SUCCESS,
  QUERY_NOTIFICATIONS_SUCCESS,
  DELETE_NOTIFICATION_SUCCESS,
  QUERY_QUICKREPORTS_SUCCESS,
  DELETE_QUICKREPORTS_SUCCESS,
} from '../constants/userConstants';
import Swal from 'sweetalert2';
import { ContactlessOutlined } from '@material-ui/icons';
import { loadFacilityRequest } from './facilityActions';

export const querySuccess = (webUsers) => ({
  type: ADMIN_QUERY_WEBUSERS_SUCCESS,
  webUserResults: webUsers,
});
export const currentUserSuccess = (user) => ({
  type: QUERY_CURRENT_WEBUSER_SUCCESS,
  userResults: user,
});
export const queryFail = () => ({
  type: ADMIN_QUERY_WEBUSERS_FAIL,
});
export const deleteUserSuccess = () => ({
  type: ADMIN_DELETE_USER_SUCCESS,
});

export const deleteUserfail = () => ({
  type: ADMIN_DELETE_USER_FAIL,
});
export const deleteFacilitySuccess = () => ({
  type: ADMIN_DELETE_FACILITY_SUCCESS,
});

export const deleteFacilityfail = () => ({
  type: ADMIN_DELETE_FACILITY_FAIL,
});
export const adminAddUserSuccess = () => ({
  type: ADMIN_ADD_USER_SUCCESS,
});

export const adminAddUserFail = () => ({
  type: ADMIN_ADD_USER_FAIL,
});

export const queryFacilityListSuccess = (facilityList) => ({
  type: ADMIN_FACILITYLIST_SUCCESS,
  facilityList: facilityList,
});

export const queryFacilityListFail = (error) => ({
  type: ADMIN_FACILITYLIST_FAIL,
});
export const queryAvailFacilityListSuccess = (facilityList) => ({
  type: ADMIN_AVAILFACILITYLIST_SUCCESS,
  facilityList: facilityList,
});

export const queryAvailFacilityListFail = (error) => ({
  type: ADMIN_AVAILFACILITYLIST_FAIL,
});
export const loadProfile = () => ({
  type: PROFILE_LOADING,
});
export const loadProfileFail = () => ({
  type: PROFILE_LOADING_FAIL,
});

export const loadProfileSuccess = () => ({
  type: PROFILE_LOADING_SUCCESS,
});
export const updatePassFail = (error) => {
  //if ($.isEmptyObject(reports)) {
  Swal.fire({
    icon: 'info',
    text: 'Unable to update password. Please try again.',
  });
  //}
  return {
    type: PROFILE_UPDATE_PASSWORD_FAIL,
  };
};
export const watchListSuccess = (watchList) => ({
  type: QUERY_WATCHLIST_SUCCESS,
  watchList: watchList,
});
export const notificationsSuccess = (notifications) => ({
  type: QUERY_NOTIFICATIONS_SUCCESS,
  notifications: notifications,
});
export const deleteNotificationSuccess = () => ({
  type: DELETE_NOTIFICATION_SUCCESS,
});
export const quickReportSuccess = (qrList) => ({
  type: QUERY_QUICKREPORTS_SUCCESS,
  quickReports: qrList,
});

export const getUsersRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/readUser');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(querySuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const getCurrentUserRequest = (where) => {
  where = "WebUsers.username = '" + where + "'";
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/readCurrentUser?where=' + where
    );
    dispatch(loadProfile());

    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(currentUserSuccess(searchResult));
        dispatch(loadProfileSuccess());
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const getCurrentUser = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/readUser');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(currentUserSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const updateUserRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/updateUser');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getUsersRequest());
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const updateUserPassRequest = (json) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/updateUserPass'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        if (response.status == 401) {
          Swal.fire({
            icon: 'info',
            text: 'Unable to update password. Please try again.',
          });
        }
        dispatch(getUsersRequest());
      })
      .catch((error) => {
        dispatch(updatePassFail(error));
      });
  };
};

export const getFacilityRequest = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getFacilities');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryFacilityListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFacilityListFail(error));
      });
  };
};

export const deleteUserRequest = (username) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/deleteUser');
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ username: username }),
    })
      .then(() => {
        dispatch(deleteUserSuccess());
      })
      .then(() => {
        dispatch(getUsersRequest());
      })
      .catch((error) => {
        dispatch(deleteUserfail(error));
      });
  };
};

export const addUserRequest = (json) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/addUser');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getUsersRequest());
      })
      .catch((error) => {
        dispatch(adminAddUserFail(error));
      });
  };
};

export const createFacilityRequest = (json, limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/webAdmin/addFacility');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(loadFacilityRequest(limit, offset, sort, dir));
      })
      .catch((error) => {
        dispatch(queryFacilityListFail(error));
      });
  };
};

export const deleteFacilityRequest = (facilityid, limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/deleteFacility'
    );
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ facilityID: facilityid }),
    })
      .then(() => {
        dispatch(deleteFacilitySuccess());
      })
      .then(() => {
        dispatch(loadFacilityRequest(limit, offset, sort, dir));
      })
      .catch((error) => {
        dispatch(deleteFacilityfail(error));
      });
  };
};

export const getAvailFacilityRequest = () => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/getAvailableFacilities'
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryAvailFacilityListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryAvailFacilityListFail(error));
      });
  };
};
export const getWatchList = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getWatchList');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(watchListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const updateUserWatchListRequest = (json) => {
  return (dispatch) => {
    console.log('update user watchlist request');
    console.log(json);
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/updateUserWatchList'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getWatchList());
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const getNotifications = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getNotifications');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(notificationsSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const deleteNotification = (patientid) => {
  console.log('deleting notification');
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/deleteNotifications'
    );
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ patientID: patientid }),
    })
      .then(() => {
        dispatch(deleteFacilitySuccess());
      })
      .then(() => {
        dispatch(getWatchList());
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const getQuickReports = () => {
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/getQuickReports');
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(quickReportSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFail(error));
      });
  };
};
export const deleteQRRequest = (qrID) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/deleteQuickReport'
    );
    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: qrID,
    })
      .then(() => {
        dispatch(getQuickReports());
      })
      .catch((error) => {
        dispatch(deleteUserfail(error));
      });
  };
};

export const addQRRequest = (json) => {
  console.log(json);
  return (dispatch) => {
    let url = new URL(process.env.REACT_APP_API_URL + '/data/addQuickReport');
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getQuickReports());
      })
      .catch((error) => {
        dispatch(adminAddUserFail(error));
      });
  };
};
export const updateQRRequest = (json) => {
  console.log(json);
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/data/updateQuickReport'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getQuickReports());
      })
      .catch((error) => {
        dispatch(adminAddUserFail(error));
      });
  };
};
