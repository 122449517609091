import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addLabRequest,
  getLabsRequest,
  deleteLabRequest,
  updateLabRequest,
} from '../actions/labManagementActions';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import '../css/FacilityLoginComponent.css';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import Swal from 'sweetalert2';

class LabManagementComponent extends Component {
  constructor() {
    super();
    this.state = {
      facilities: [],
      facilityList: [],
      logins: [],
      labs: [],
      labInfoDialog: false,
      currentLab: [],
      editLabDialogOpen: false,
      facScraper: '',
    };
  }
  static getDerivedStateFromProps(props, state) {
    let facilityList = [''];
    props.facilities.forEach((fac) => {
      facilityList.push(fac);
    });
    return {
      ...state,
      facilities: props.facilities,
      facilityList: facilityList,
      logins: props.logins,
      labs: props.labs,
    };
  }

  componentDidMount() {
    this.props.getLabsRequest();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => { });
  }

  addLab = () => {
    let loginJson = {
      labName: this.state.labName,
      labAddress: this.state.labAddress,
      labCity: this.state.labCity,
      labState: this.state.labState,
      labZipcode: this.state.labZipcode,
    };
    this.props.addLabRequest(loginJson);
  };
  updateLab = () => {
    let labJson = {
      labName: this.state.labName,
      labID: this.state.labID,
      labAddress: this.state.labAddress,
      labCity: this.state.labCity,
      labState: this.state.labState,
      labZipcode: this.state.labZipcode,
    };
    this.props.updateLabRequest(labJson);
  };
  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];
    let labInfoDialog = () => {
      return (
        <Dialog
          open={this.state.labInfoDialog}
          onClose={(evt) => {
            this.setState({ labInfoDialog: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogTitle id="addTitle">Laboratory Information</DialogTitle>
          <DialogContent>
            <Grid>
              <GridRow>
                <GridCell span={12}></GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Name: </Typography>
                </GridCell>
                <GridCell span={6}>{this.state.currentLab.labName}</GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Address: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {(this.state.currentLab.labAddress === '' || this.state.currentLab.labAddress === null) && (this.state.currentLab.labCity === null || this.state.currentLab.labCity === '')
                  && (this.state.currentLab.labState === null || this.state.currentLab.labState === '') && (this.state.currentLab.labZipcode === null || this.state.currentLab.labZipcode === '')
                    ? 'Unknown Address'
                    : this.state.currentLab.labAddress +
                    ' ' +
                    this.state.currentLab.labCity +
                    ' ' +
                    this.state.currentLab.labState +
                    ' ' +
                    this.state.currentLab.labZipcode}

                </GridCell>
              </GridRow>
              <hr></hr>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ labInfoDialog: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let editLabDialog = () => {
      return (
        <Dialog
          open={this.state.editLabDialogOpen}
          onClose={(evt) => {
            this.setState({ editLabDialogOpen: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            {'Modify Lab "' + this.state.labName + '"'}
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Lab Name:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    required
                    style={{ width: '500px' }}
                    placeholder="Lab Name"
                    outlined
                    name="labName"
                    value={this.state.labName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Address:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    outlined
                    placeholder="Address"
                    name="labAddress"
                    value={this.state.labAddress}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>City/State/Zip:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '250px' }}
                    outlined
                    placeholder="City"
                    name="labCity"
                    value={this.state.labCity}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell
                  span={3}
                  style={{ left: '-20%', position: 'relative', width: '235px' }}
                >
                  <Select
                    outlined
                    native
                    displayEmpty
                    value={this.state.labState}
                    onChange={(event) =>
                      this.setState({
                        labState: event.currentTarget.value,
                      })
                    }
                    placeholder="State"
                    inputProps={{
                      name: 'labState',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}></GridCell>
                <GridCell span={9}>
                  <GridCell span={3}>
                    <TextField
                      style={{ width: '166px' }}
                      outlined
                      placeholder="Zipcode"
                      name="labZipcode"
                      value={this.state.labZipcode}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Button
              action="update"
              unelevated
              icon="save"
              onClick={(evt) => {
                if (this.isEmpty(this.state.labName.trim())) {
                  evt.stopPropagation();
                  Swal.fire({
                    icon: 'error',
                    text: 'Please fill out all search fields',
                  });
                } else {
                  this.updateLab();
                  this.setState({ editLabDialogOpen: false });
                }
              }}
            >
              Update
            </Button>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  editLabDialogOpen: false,
                  labState: '',
                  labAddress: '',
                  labName: '',
                  labCity: '',
                  labZipcode: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let addLabDialog = () => {
      return (
        <Dialog
          open={this.state.addLabDialogOpen}
          onClose={(evt) => {
            this.setState({ addLabDialogOpen: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Add Laboratory
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>Lab Name:</GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    placeholder="Lab Name"
                    outlined
                    required
                    name="labName"
                    value={this.state.labName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Address:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    outlined
                    placeholder="Address"
                    name="labAddress"
                    value={this.state.labAddress}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>City/State/Zip:</Typography>
                </GridCell>
                <GridCell span={4}>
                  <TextField
                    style={{ width: '250px' }}
                    outlined
                    placeholder="City"
                    name="labCity"
                    value={this.state.labCity}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell
                  span={3}
                  style={{ left: '-20%', position: 'relative', width: '235px' }}
                >
                  <Select
                    outlined
                    native
                    displayEmpty
                    value={this.state.labState}
                    onChange={(event) =>
                      this.setState({
                        labState: event.currentTarget.value,
                      })
                    }
                    placeholder="State"
                    inputProps={{
                      name: 'labState',
                    }}
                  >
                    {states.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </Select>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}></GridCell>
                <GridCell span={9}>
                  <GridCell span={3}>
                    <TextField
                      style={{ width: '166px' }}
                      outlined
                      placeholder="Zipcode"
                      name="labZipcode"
                      value={this.state.labZipcode}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridCell>
              </GridRow>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              action="add"
              icon="add"
              unelevated
              onClick={(evt) => {
                if (this.isEmpty(this.state.labName.trim())) {
                  evt.stopPropagation();
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'you must fill out the required fields',
                  });
                } else {
                  this.addLab();
                  this.setState({
                    addLabDialogOpen: false,
                  });
                }
              }}
            >
              Add
            </DialogButton>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({
                  addLabDialogOpen: false,
                  labState: '',
                  labAddress: '',
                  labName: '',
                  labCity: '',
                  labZipcode: '',
                });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let labData = () =>
      this.state.labs.map((lab, i) => {
        return (
          <DataTableRow key={i}>
            <DataTableCell
              onClick={() => {
                this.setState({
                  labInfoDialog: true,
                  currentLab: lab,
                });
              }}
              style={{
                border: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              {lab.labName}
            </DataTableCell>
            <DataTableCell>
              <Button
                disabled={this.props.role !== 'Tsalta Admin'}
                icon="edit"
                onClick={() =>
                  this.setState({
                    editLabDialogOpen: true,
                    labName: lab.labName,
                    labID: lab.labID,
                    labAddress: lab.labAddress,
                    labCity: lab.labCity,
                    labState: lab.labState,
                    labZipcode: lab.labZipcode,
                  })
                }
              >
                Modify
              </Button>
              <Button
                disabled={this.props.role !== 'Tsalta Admin'}
                icon="delete"
                onClick={() => {
                  Swal.fire({
                    title:
                      'Are you sure you want to delete Lab "' +
                      lab.labName +
                      '" ?',
                    text: 'You will not be able to revert this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.props.deleteLabRequest(lab.labID);
                      Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Delete
              </Button>
            </DataTableCell>
          </DataTableRow>
        );
      });

    return (
      <div>
        <div id="facilityHead">
          <div id="facility">
            <Typography use="headline4"> Laboratories </Typography>
          </div>
        </div>
        <DataTable id="facilityLoginTable" stickyRows={1} stickyColumns={1}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                    width: '50%',
                  }}
                  value={this.state.facilitySort}
                  sort={this.state.facilitySort}
                  onSortChange={(sortDir) => {
                    this.setState(
                      {
                        facilitySort: sortDir,
                      },
                      () => {
                        this.sortData(sortDir);
                      }
                    );
                  }}
                >
                  Lab Name
                </DataTableHeadCell>
                <DataTableHeadCell
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  Actions
                </DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>{labData()}</DataTableBody>
          </DataTableContent>
        </DataTable>

        <div>
          <div>
            <Button
              id="addButton"
              disabled={this.props.role !== 'Tsalta Admin'}
              variant="contained"
              onClick={() => {
                this.setState({
                  addLabDialogOpen: true,
                  labName: '',
                  labAddress: '',
                  labCity: '',
                  labState: '',
                  labZipcode: '',
                });
              }}
            >
              Add Lab
            </Button>
          </div>
        </div>
        {labInfoDialog()}
        {addLabDialog()}
        {editLabDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.login.decodedJWT.role,
  facilities: state.admin.facilities,
  logins: state.facilityLogins.logins,
  labs: state.labs.labs,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getLabsRequest: () => dispatch(getLabsRequest()),
    addLabRequest: (json) => dispatch(addLabRequest(json)),
    deleteLabRequest: (labID) => dispatch(deleteLabRequest(labID)),
    updateLabRequest: (json) => dispatch(updateLabRequest(json)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabManagementComponent);
