import React from 'react';
import { connect } from 'react-redux';

import {
  DataTable,
  DataTableBody,
  DataTableHeadCell,
  DataTableRow,
  DataTableHead,
  DataTableCell,
  DataTableContent,
} from '@rmwc/data-table';
import {
  resultRequest,
  searchRequest,
  queryReports,
  obrRequest,
  printReports,
  analyzedReports,
  getSubtestRequest,
  getTestNameRequest,
  advancedSearch,
  resultClickedDone,
  patientLabResults,
  clearSearch,
} from '../actions/resultsActions';
import {
  facesheetRequest,
  dmvRequest,
  updateDMVRequest,
} from '../actions/patientActions';
import { getFacilityRequest } from '../actions/userActions';
import { Button } from '@rmwc/button';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { TextField } from '@rmwc/textfield';
import { Select } from '@rmwc/select';
import '../css/resultComponent.css';
import TablePagination from '@material-ui/core/TablePagination';
import { Typography } from '@rmwc/typography';
import { pdfjs } from 'react-pdf';
import {
  Collapse,
  Table,
  TableCell,
  TableRow,
  IconButton,
  TableContainer,
} from '@material-ui/core';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import $ from 'jquery';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { openNewTabFile } from '../utils/Utils';
import '@rmwc/select/styles';
import '@rmwc/data-table/styles';
import { Checkbox } from '@rmwc/checkbox';

import '@rmwc/textfield/styles';
import { Chip, ChipSet } from '@rmwc/chip';
import '@rmwc/chip/styles';
import '@rmwc/fab/styles';
import Swal from 'sweetalert2';
import '@rmwc/circular-progress/styles';
import { CircularProgress } from '@rmwc/circular-progress';
import '@rmwc/linear-progress/styles';
import PatientInfoComponent from './PatientInfoComponent';
import { openDialog } from '../actions/dialogActions';

class ResultComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      reportSort: 0,
      results: [],
      filteredResults: [],
      currentResult: null,
      reportIDSort: null,
      reportDateSort: 2,
      patientNameSort: null,
      facilitySort: null,
      pagnatedResults: [],
      offset: 0,
      resultsPerPage: 100,
      resultsPage: 0,
      resultsTotal: 100,
      abnormalSort: 0,
      sortCol: 'reportDate',
      sortDir: 'DESC',
      patientInfoSort: 0,
      patientInfoDialog: false,
      patientLabDialog: false,
      printDialog: false,
      errorDialog: false,
      advancedSearchDialog: false,
      reports: [],
      obrResults: [],
      pageNumber: 1,
      openObrID: new Set(),
      searchDate: new Date().toISOString().slice(0, 10),
      printChecked: [],
      printReports: [],
      facilities: [],
      subtest: [],
      facilityList: [],
      subtestList: [],
      testNameList: [],
      facilitySearch: '',
      subtestSearch: '',
      abnormalSearch: '',
      analyzedSearch: '',
      testNameSearch: '',
      query: '',
      checkAll: false,
      checkedOnOpen: false,
      values: [{ value: null }],
      advSearching: false,
      searching: false,
      currentPatient: [],
      diagnosis: [],
      vitals: [],
      medications: [],
      tabIndex: 0,
      dmv: [],
      searchCol: '',
      obrNoteDialog: false,
      obxNoteDialog: false,
      currentObrNote: '',
      currentObrTest: '',
      currentObxNote: '',
      currentObxTest: '',
      tempSearchCol: '',
      tempQuery: '',
    };
    this.clearChecks = this.clearChecks.bind(this);
    this.keyPress = this.keyPress.bind(this);
    this.advKeyDown = this.advKeyDown.bind(this);
  }
  static getDerivedStateFromProps(props, state) {
    let obrList = [];
    let obrSet = new Set();
    let obrResults = $.extend(true, [], props.obrResults);
    if (props.obrResults.length > 0) {
      props.obrResults.forEach((result) => {
        obrSet.add(result.obrID);
      });
      obrSet.forEach((id) => {
        let abnormal = 0;
        let obr;
        let highLow = '';
        for (var item of obrResults) {
          if (item.obrID === id) {
            obr = item;

            if (item.abnormal === 1) {
              abnormal = 1;
            }

            if (
              item.highLow === 'HH' ||
              item.highLow === 'hh' ||
              item.highLow === 'LL' ||
              item.highLow === 'll'
            ) {
              highLow = item.highLow;
            }
          }
        }
        obr.abnormal = abnormal;
        obr.highLow = highLow;
        obrList.push(obr);
      });
    }
    let facilityList = [];
    props.facilities.forEach((facility) => {
      if (!facilityList.includes(facility.facilityName)) {
        facilityList.push(facility.facilityName);
      }
    });
    let subtestList = [];
    props.subtest.forEach((subtest) => {
      if (subtest.testName !== undefined) {
        subtestList.push(subtest.testName);
      }
    });
    let testNameList = [];
    props.testName.forEach((testName) => {
      if (testName.testName !== undefined) {
        testNameList.push(testName.testName);
      }
    });
    return {
      ...state,
      results: props.results,
      pagnatedResults: props.reports,
      reports: props.reports,
      obrResults: props.obrResults,
      obrList: obrList,
      facilities: props.facilities,
      facilityList: facilityList,
      subtestList: subtestList,
      testNameList: testNameList,
      subtest: props.subtest,
      testName: props.testName,
      resultsTotal: props.resultTotal,
      currentPatient: props.facesheet,
      diagnosis: props.diagnosis,
      vitals: props.vitals,
      medications: props.medications,
      dmv: props.dmv,
      searchButtonClicked: props.resultClicked,
      patientInfoDialog: props.patientInfoDialog,
      role: props.role,
    };
  }
  componentDidMount(data) {
    if (!this.props.patientQuery && !this.props.subtestQuery) {
      this.props.queryResults(
        this.state.resultsPerPage,
        this.state.offset,
        this.state.sortCol,
        this.state.sortDir
      );
    }
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    this.props.getFacilities();
    this.props.getSubtests();
    this.props.getTestName();
  }
  componentDidUpdate() {
    if (this.state.searchButtonClicked) {
      this.setState(
        {
          searchCol: '',
          query: '',
          sortCol: 'reportDate',
          sortDir: 'DESC',
          advSearching: false,
          searching: false,
          resultsPage: 0,
          offset: 0,
        },
        () => {
          this.props.queryResults('100', '0', 'reportDate', 'DESC');
        }
      );
      this.props.resultClickedDone();
    }
    this.render();
  }
  handleProfile() {
    this.props.history.push('/app/result');
  }

  handleChangePage = (event, newPage) => {
    let offset = this.state.resultsPerPage * newPage;
    let table = document.getElementById('resultTable');
    table.scrollTo(0, 0);
    this.setState(
      {
        resultsPage: newPage,
        offset: offset,
      },
      () => {
        if (this.state.searching) {
          this.props.searchResults(
            this.state.tempSearchCol,
            this.state.tempQuery,
            this.state.resultsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else if (this.state.advSearching) {
          this.props.advancedSearch(
            this.state.values,
            this.state.resultsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else if (this.props.patientQuery) {
          this.props.patientLabResults(
            this.props.patientQueryID,
            this.props.patientQueryFacID,
            this.state.resultsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        } else {
          this.props.queryResults(
            this.state.resultsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      }
    );
  };
  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        resultsPage: 0,
        resultsPerPage: parseInt(event.target.value, 10),
      },
      () => {
        this.handleChangePage(null, this.state.resultsPage);
      }
    );
  };
  handleAdvChange = (event, x, i) => {
    let array = this.state.values;
    let object = x;
    object['value'] = event.currentTarget.value;
    if (x.dropVal === 'Report Date') {
      object['value'] = event.currentTarget.value;
    }
    array.splice(i, 1, object);
    this.setState({
      values: array,
    });
  };

  keyPress(e) {
    if (e.keyCode === 13) {
      e.preventDefault();

      this.setState(
        {
          searching: true,
          offset: 0,
          resultsPage: 0,
          advSearc3hing: false,
        },
        () => {
          this.clearChecks();
          this.props.searchResults(
            this.state.searchCol,
            this.state.query,
            this.state.resultsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      );
    }
  }

  advKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.setState(
        {
          advancedSearchDialog: false,
          advSearching: true,
          offset: 0,
          resultsPage: 0,
          searching: false,
        },
        () => {
          this.clearChecks();
          this.props.advancedSearch(
            this.state.values,
            this.state.resultsPerPage,
            this.state.offset,
            this.state.sortCol,
            this.state.sortDir
          );
        }
      );
    }
  }
  clearChecks = () => {
    Object.keys(this.state).map((key) => {
      let regex = new RegExp('checkd*');
      if (regex.test(key)) {
        this.setState({ [key]: false });
      }
    });
    this.setState({ printChecked: [] });
  };
  checkAll = (evt) => {
    var newState = this.state.printChecked.slice();
    this.state.pagnatedResults.map((result, i) => {
      // let printChecked = this.state.printChecked;
      // printChecked.push(result.reportID);

      let checkname = 'check' + result.reportID;
      if (
        !this.state.printChecked.includes(result.reportID) &&
        evt.currentTarget.checked
      ) {
        newState.push(result.reportID);
        this.setState({
          [checkname]: true,
        });
      } else {
        newState = [];
        this.clearChecks();
        // newState = this.state.printChecked.slice();
        // for (var i = 0; i < newState.length; i++) {
        //   console.log(newState[i]);
        //   if (newState[i] === result.reportID) {
        //     newState.splice(i, 1);
        //     i--;
        //   }
        // }
        // this.setState({
        //   [checkname]: false,
        // });
      }
    });
    this.setState({
      printChecked: newState,
      checkAll: evt.currentTarget.checked,
    });
  };
  searchButton = () => {};

  emptySearch = () => {
    let empty = false;
    this.state.values.forEach((term) => {
      if (term.value) {
        if (term.value === null) {
          empty = true;
        }
      } else {
        empty = true;
      }
    });
    return empty;
  };

  sortData(sortDir, column) {
    // this.props.searchResults(
    //   this.state.searchCol,
    //   this.state.query,
    //   this.state.resultsPerPage,
    //   this.state.offset,
    //   this.state.sortCol,
    //   this.state.sortDir
    // );

    if (sortDir === 1) {
      if (
        this.state.query !== null &&
        this.state.query !== undefined &&
        this.state.query !== '' &&
        this.state.advSearching === false
      ) {
        this.props.searchResults(
          this.state.searchCol,
          this.state.query,
          this.state.resultsPerPage,
          this.state.offset,
          column,
          'ASC'
        );
      } else if (this.state.advSearching) {
        this.props.advancedSearch(
          this.state.values,
          this.state.resultsPerPage,
          this.state.offset,
          column,
          'ASC'
        );
      } else if (this.props.patientQuery) {
        this.props.patientLabResults(
          this.props.patientQueryID,
          this.props.patientQueryFacID,
          this.state.resultsPerPage,
          this.state.offset,
          this.state.sortCol,
          this.state.sortDir
        );
      } else {
        this.props.queryResults(
          this.state.resultsPerPage,
          this.state.offset,
          column,
          'ASC'
        );
      }
      this.setState({
        sortCol: column,
        sortDir: 'ASC',
      });
    } else if (sortDir === -1) {
      if (
        this.state.query !== null &&
        this.state.query !== undefined &&
        this.state.query !== '' &&
        this.state.advSearching === false
      ) {
        this.props.searchResults(
          this.state.searchCol,
          this.state.query,
          this.state.resultsPerPage,
          this.state.offset,
          column,
          'DESC'
        );
      } else if (this.state.advSearching) {
        this.props.advancedSearch(
          this.state.values,
          this.state.resultsPerPage,
          this.state.offset,
          column,
          'DESC'
        );
      } else if (this.props.patientQuery) {
        this.props.patientLabResults(
          this.props.patientQueryID,
          this.props.patientQueryFacID,
          this.state.resultsPerPage,
          this.state.offset,
          this.state.sortCol,
          this.state.sortDir
        );
      } else {
        this.props.queryResults(
          this.state.resultsPerPage,
          this.state.offset,
          column,
          'DESC'
        );
      }
      this.setState({
        sortCol: column,
        sortDir: 'DESC',
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeDate = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ totalPages: numPages, pageNumber: 1 });
  };
  getColor = (abnormal) => {
    if (abnormal === 0) return 'rgb(189, 178, 178)';
    if (abnormal === 1) return 'rgb(236, 133, 133)';
    return '';
  };
  toggleSelected() {
    this.setState({});
  }
  clearSearch() {
    this.props.clearSearch();
    this.setState(
      {
        searchCol: '',
        query: '',
        sortCol: 'reportDate',
        sortDir: 'DESC',
        advSearching: false,
        searching: false,
        resultsPage: 0,
        offset: 0,
      },
      () => {
        this.props.queryResults(
          this.state.resultsPerPage,
          this.state.offset,
          this.state.sortCol,
          this.state.sortDir
        );
      }
    );

    this.clearChecks();
  }

  addClick() {
    this.setState((prevState) => ({
      values: [...prevState.values, { value: null }],
    }));
  }

  removeClick(i) {
    let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  }
  updateDMV = () => {
    let dmvJson = {
      patientFirstName: this.state.currentPatient.patientFirstName,
      patientLastName: this.state.currentPatient.patientLastName,
      patientID: this.state.currentPatient.patientID,
      facilityName: this.state.currentPatient.facilityName,
      facilityLoginID: this.state.currentPatient.facilityLoginID,
    };
    this.props.updateDMVRequest(dmvJson);
  };

  render() {
    let obrNoteDialog = () => {
      return (
        <Dialog
          fullWidth
          maxWidth="sm"
          id="obrNoteDialog"
          variant="contained"
          onClick={() => {}}
          open={this.state.obrNoteDialog}
          onClose={(evt) => {
            this.setState({ obrNoteDialog: false });
          }}
          onClosed={(evt) => {}}
        >
          <pre
            style={{
              width: '600px',
              height: '300px',
              whiteSpace: 'pre-wrap',
              overflow: 'scroll',
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <div
              style={{
                fontSize: '20px',
                color: 'dodgerblue',
                textAlign: 'center',
              }}
            >
              {this.state.currentObrTest + ' Notes'}
            </div>
            {this.state.currentObrNote.replace('\\X0d0a\\', '\\n ')}
          </pre>
          <div
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderBottom: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Button
              icon="close"
              cancel
              style={{ width: '20%', left: '80%' }}
              onClick={() => {
                this.setState({ obrNoteDialog: false });
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      );
    };
    let obxNoteDialog = () => {
      return (
        <Dialog
          fullWidth
          maxWidth="sm"
          id="obxNoteDialog"
          variant="contained"
          onClick={() => {}}
          open={this.state.obxNoteDialog}
          onClose={(evt) => {
            this.setState({ obxNoteDialog: false });
          }}
          onClosed={(evt) => {}}
        >
          <pre
            style={{
              width: '600px',
              height: '300px',
              whiteSpace: 'pre-wrap',
              borderTop: 'solid',
              overflow: 'scroll',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <div
              style={{
                fontSize: '20px',
                color: 'dodgerblue',
                textAlign: 'center',
              }}
            >
              {this.state.currentObxTest + ' Notes'}
            </div>
            {this.state.currentObxNote.replace('\\X0d0a\\', '\\n ')}
          </pre>
          <div
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderBottom: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Button
              icon="close"
              cancel
              style={{ width: '20%', left: '80%' }}
              onClick={() => {
                this.setState({ obxNoteDialog: false });
              }}
            >
              Close
            </Button>
          </div>
        </Dialog>
      );
    };
    let patientLabDialog = () => {
      return (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={this.state.patientLabDialog}
          onClose={(evt) => {
            this.setState({ patientLabDialog: false });
          }}
          onClosed={(evt) => {}}
          renderToPortal="true"
        >
          <DialogTitle id="addTitle">
            <Typography use="headline5">Lab Result Details</Typography>
          </DialogTitle>
          <DialogContent style={{ minHeight: '400 px', height: '400px' }}>
            <div id="obrHead"></div>
            <TableContainer component={Paper}>
              <Table stickyHeader="true">
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell id="cellBold">Patient Name</TableCell>
                  <TableCell id="cellBold">Doctor Name</TableCell>
                  <TableCell id="cellBold">Test Name</TableCell>
                  <TableCell id="cellBold">Test Date</TableCell>
                  <TableCell id="cellBold">Notes</TableCell>
                </TableRow>
                {patientData()}
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <ChipSet filter>
              <Chip
                selected={
                  this.state.currentResult !== null
                    ? this.state.currentResult.analyzed
                    : false
                }
                checkmark
                onInteraction={() => {
                  this.toggleSelected();
                  if (this.state.currentResult.analyzed === 1) {
                    this.props.analyzedReports(
                      0,
                      this.state.currentResult.reportID
                    );
                    let current = this.state.currentResult;
                    current.analyzed = 0;
                    this.setState({ currentResult: current });
                  } else if (this.state.currentResult.analyzed === 0) {
                    this.props.analyzedReports(
                      1,
                      this.state.currentResult.reportID
                    );
                    let current = this.state.currentResult;
                    current.analyzed = 1;
                    this.setState({ currentResult: current });
                  }
                }}
              >
                Reviewed
              </Chip>
            </ChipSet>

            <Button
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ patientLabDialog: false });
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    };
    let advancedSearchDialog = () => {
      let options = [
        'Abnormal Results',
        'Reviewed',
        'Report Date',
        'Patient Last Name',
        'Facility',
        'Patient Allergies',
        'Test Name',
        'Subtest',
        'Critical',
      ];
      options.sort();
      return (
        <Dialog
          fullWidth={'lg'}
          maxWidth="lg"
          open={this.state.advancedSearchDialog}
          onClose={(evt) => {
            this.setState({ advancedSearchDialog: false });
          }}
          onClosed={(evt) => {}}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Advanced Search
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
              minHeight: '400 px',
              height: '400px',
            }}
          >
            {this.state.values.map((x, i) => (
              <div id="advancedSearch" key={x.index}>
                <GridRow>
                  <GridCell span={5}>
                    <Select
                      value={x.dropVal}
                      id={i}
                      label="Advanced Search"
                      outlined
                      options={options}
                      onChange={(event) => {
                        let array = this.state.values;
                        let object = x;
                        object = { dropVal: event.currentTarget.value };
                        array.splice(i, 1, object);
                        this.setState({
                          values: array,
                        });
                      }}
                    ></Select>
                  </GridCell>
                  <GridCell span={5}>
                    {x.dropVal === 'Report Date' ||
                    x.dropVal === 'Abnormal Results' ||
                    x.dropVal === 'Reviewed' ||
                    x.dropVal === 'Test Name' ||
                    x.dropVal === 'Subtest' ||
                    x.dropVal === 'Facility' ||
                    x.dropVal === 'Critical' ? (
                      <div>{advancedSearchField(x, i)}</div>
                    ) : (
                      <div key={i}>
                        <form>
                          <TextField
                            id={i}
                            value={x.value || ''}
                            outlined
                            label="Search Term"
                            name="advanced"
                            onChange={(event) => {
                              this.handleAdvChange(event, x, i);
                            }}
                            onKeyDown={this.advKeyDown}
                          />
                        </form>
                      </div>
                    )}
                  </GridCell>
                  <GridCell span={2}>
                    <Button
                      id={i}
                      onClick={() => {
                        this.removeClick(i);
                      }}
                    >
                      Remove
                    </Button>
                  </GridCell>
                </GridRow>
              </div>
            ))}
            <Button
              style={{ position: 'absolute', left: '39%', top: '81%' }}
              id="createReportButton"
              icon="search"
              label="Search"
              onClick={() => {
                if (!this.emptySearch()) {
                  this.clearChecks();
                  this.props.advancedSearch(
                    this.state.values,
                    this.state.resultsPerPage,
                    this.state.offset,
                    this.state.sortCol,
                    this.state.sortDir
                  );
                  this.setState({
                    advancedSearchDialog: false,
                    advSearching: true,
                    searching: false,
                  });
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'you must make a selection before searching',
                  });
                }
              }}
            ></Button>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <Button
              style={{
                backgroundColor: 'dodgerblue',
                color: 'white',
                position: 'absolute',
                right: '20.5%',
              }}
              icon="add"
              label="Add Field"
              outlined
              onClick={() => {
                this.addClick();
              }}
            ></Button>
            <Button
              style={{ backgroundColor: 'dodgerblue', color: 'white' }}
              label="New Search"
              icon="refresh"
              onClick={() => {
                this.clearChecks();
                this.setState({
                  values: [{ value: null, dropVal: '' }],
                });
              }}
            ></Button>
            <Button
              style={{ backgroundColor: 'dodgerblue', color: 'white' }}
              icon="close"
              onClick={() => {
                this.setState({ advancedSearchDialog: false });
              }}
              action="close"
              elevated
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    };
    let printDialog = () => {
      return (
        <Dialog
          open={this.state.printDialog}
          onClose={(evt) => {
            this.setState({ printDialog: false });
          }}
        >
          <DialogContent>
            <Typography id="printModal" use="headline5">
              Print Results
            </Typography>
            <div id="reportDateSelect">
              <div id="searchStartDate">
                <Typography>Start Date</Typography>
                <form noValidate>
                  <TextField
                    id="searchStartDateCalender"
                    name="searchStartDate"
                    type="date"
                    value={this.state.searchStartDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleChange}
                  />
                </form>
              </div>
              <div id="searchEndDate">
                <Typography>End Date</Typography>
                <form noValidate>
                  <TextField
                    id="searchEndDateCalender"
                    name="searchEndDate"
                    type="date"
                    value={this.state.searchEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={this.handleChange}
                  />
                </form>
              </div>
            </div>
            <div id="reportPrintButton">
              <Button>Print Report</Button>
            </div>
          </DialogContent>
        </Dialog>
      );
    };

    let patientData = () => {
      if (this.state.obrResults.length === 0) {
        return (
          <Typography id="noOBR" use="headline5">
            No results
          </Typography>
        );
      } else {
        let obrData = this.state.obrList.map((obr, i) => {
          var epoch = obr.obrDate + new Date().getTimezoneOffset() * -1;
          var hrDate = new Date(epoch);
          var newDate =
            hrDate.getMonth() +
            1 +
            '/' +
            hrDate.getDate() +
            '/' +
            hrDate.getFullYear();
          const getColor = (abnormal, highLow) => {
            if (
              highLow === 'HH' ||
              highLow === 'hh' ||
              highLow === 'LL' ||
              highLow === 'll'
            )
              return 'rgb(255,77,77)';
            if (abnormal === 0) return 'rgb(189, 178, 178)';
            if (abnormal === 1) return 'rgb(236, 133, 133)';
            return '';
          };
          let background = getColor(obr.abnormal, obr.highLow);
          return [
            <TableRow key={obr.obrID} style={{ backgroundColor: background }}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(obr.obrID)}
                >
                  {isOBROpen(obr.obrID) ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                {obr.patientLastName + ', ' + obr.patientFirstName}
              </TableCell>
              <TableCell>
                {obr.doctorLastName + ', ' + obr.doctorFirstName}
              </TableCell>
              <TableCell>{obr.testName}</TableCell>
              <TableCell>{newDate}</TableCell>
              <TableCell>
                {obr.obrNote !== null && obr.obrNote !== undefined ? (
                  <Button
                    icon="draw"
                    onClick={() => {
                      this.setState({
                        obrNoteDialog: true,
                        currentObrNote: obr.obrNote,
                        currentObrTest: obr.testName,
                      });
                    }}
                  ></Button>
                ) : null}
              </TableCell>
            </TableRow>,
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse
                  in={isOBROpen(obr.obrID)}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box margin={1}>
                    <Table stickyHeader="true">
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Typography>
                            patient allergies:{' '}
                            {obr.patientAllergies === undefined
                              ? 'no allergies'
                              : obr.patientAllergies}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        {/* <TableCell id="cellBold">Lab Name</TableCell>
                        <TableCell id="cellBold">Patient Name</TableCell> */}

                        {/* <TableCell id="cellBold">Test Date</TableCell> */}
                        <TableCell align="left" id="cellBold">
                          Abnormal
                        </TableCell>

                        <TableCell
                          id="cellBold"
                          style={{ textAlign: 'left', width: '17%' }}
                        >
                          Subtest
                        </TableCell>
                        <TableCell align="left" id="cellBold">
                          Value
                        </TableCell>
                        <TableCell align="left" id="cellBold">
                          Flag
                        </TableCell>
                        <TableCell align="left" id="cellBold">
                          Range
                        </TableCell>
                        <TableCell align="left" id="cellBold">
                          File
                        </TableCell>
                        <TableCell align="left" id="cellBold">
                          Notes
                        </TableCell>
                      </TableRow>
                      {obxData(obr.obrID)}
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>,
          ];
        });
        return obrData;
      }
    };

    let obxData = (obrID) => {
      const getColor = (abnormal, highLow) => {
        if (
          highLow === 'HH' ||
          highLow === 'hh' ||
          highLow === 'LL' ||
          highLow === 'll'
        )
          return 'rgb(255,77,77)';
        if (abnormal === 0) return 'rgb(189, 178, 178)';
        if (abnormal === 1) return 'rgb(236, 133, 133)';
        return '';
      };

      let contains = false;
      let obxData = this.state.obrResults.map((obx, i) => {
        var epoch = obx.obrDate + new Date().getTimezoneOffset() * -1;
        var hrDate = new Date(epoch);
        var newDate =
          hrDate.getMonth() +
          1 +
          '/' +
          hrDate.getDate() +
          '/' +
          hrDate.getFullYear();
        let abnormal = '';
        if (obx.abnormal === 0) {
          abnormal = 'N';
        } else {
          abnormal = 'Y';
        }
        if (obrID === obx.obrID) {
          contains = true;
          let background = getColor(obx.abnormal, obx.highLow);

          return [
            <TableRow
              id="obxTable"
              style={{ backgroundColor: background }}
              key={i}
            >
              {/* <TableCell>{obx.labName}</TableCell>
              <TableCell>
                {obx.patientLastName + ', ' + obx.patientFirstName}
              </TableCell> */}

              {/* <TableCell>{newDate}</TableCell> */}

              <TableCell align="left" style={{ width: '10px' }}>
                {abnormal}
              </TableCell>

              <TableCell align="left">{obx.subtest}</TableCell>
              <TableCell align="left">{obx.subtestValue}</TableCell>
              {obx.highLow !== null &&
              obx.highLow !== undefined &&
              obx.highLow !== 'A' ? (
                <TableCell align="left">
                  {obx.highLow === 'HH' || obx.highLow === 'hh'
                    ? 'Critical High'
                    : obx.highLow === 'LL' || obx.highLow === 'll'
                    ? 'Critical Low'
                    : obx.highLow === 'H' || obx.highLow === 'h'
                    ? 'High'
                    : obx.highLow === 'L' || obx.highLow == 'l'
                    ? 'Low'
                    : obx.highLow}
                </TableCell>
              ) : (
                <TableCell>{''}</TableCell>
              )}
              {obx.testRange !== null &&
              obx.testRange !== undefined &&
              obx.units !== null &&
              obx.units !== undefined ? (
                <TableCell>{obx.testRange + ' ' + obx.units}</TableCell>
              ) : obx.testRange !== null &&
                obx.testRange !== undefined &&
                (obx.units == null || obx.units == undefined) ? (
                <TableCell>{obx.testRange}</TableCell>
              ) : obx.units !== null &&
                obx.units !== undefined &&
                (obx.testRange == null || obx.testRange == undefined) ? (
                <TableCell>{obx.units}</TableCell>
              ) : (
                <TableCell></TableCell>
              )}
              <TableCell style={{ width: '10px' }}>
                {obx.attachment !== undefined && obx.attachment !== null ? (
                  <Button
                    icon="file_present"
                    onClick={() => {
                      openNewTabFile('test pdf', obx.attachment);
                    }}
                  ></Button>
                ) : (
                  <div />
                )}
              </TableCell>
              <TableCell style={{ width: '10px' }}>
                {obx.obxNote !== null && obx.obxNote !== undefined ? (
                  <Button
                    icon="draw"
                    onClick={() => {
                      this.setState({
                        obxNoteDialog: true,
                        currentObxNote: obx.obxNote,
                        currentObxTest: obx.subtest,
                      });
                    }}
                  ></Button>
                ) : null}
              </TableCell>
            </TableRow>,
            <div></div>,
          ];
        } else {
          return null;
        }
      });
      if (contains) {
        return obxData;
      } else {
        return (
          <Typography id="noOBX" use="heading1">
            No results
          </Typography>
        );
      }
    };

    let setOpen = (obrID) => {
      let openlist = this.state.openObrID;
      let contains = false;
      openlist.forEach((id) => {
        if (id === obrID) {
          contains = true;
          openlist.delete(id);
        }
      });
      if (!contains) {
        openlist.add(obrID);
      }
      this.setState({ openObrID: openlist });
    };

    let isOBROpen = (obrID) => {
      let openlist = this.state.openObrID;
      let contains = false;
      openlist.forEach((id) => {
        if (id === obrID) {
          contains = true;
        }
      });
      return contains;
    };

    let resultTableInfo = () => {
      if (this.state.reports === undefined || this.state.reports.length === 0) {
        if (this.props.searchGenerating) {
          return <div></div>;
        } else {
          return (
            // <Typography id="noResult" use="headline5">
            //   No Results
            // </Typography>
            <Box style={{ display: 'block' }} id="searchLoadBar">
              <CircularProgress size={'75'}></CircularProgress>
              <Typography use="headline3">Loading</Typography>
            </Box>
          );
        }
      } else {
        let resultData = this.state.pagnatedResults.map((report, i) => {
          let rDate = new Date(report.reportDate);

          const getColor = (abnormal, critical) => {
            if (critical === 1) return 'rgb(255,77,77)';
            if (abnormal === 0) return 'white';
            if (abnormal === 1) return 'rgb(236, 133, 133)';
            return '';
          };
          let background = getColor(
            report.reportAbnormal,
            report.reportCritical
          );

          let checkname = 'check' + report.reportID;
          return (
            <DataTableRow
              key={i}
              style={{ backgroundColor: background }}
              activated={this.state.currentResult === report ? true : false}
            >
              <DataTableCell style={{ width: '10px' }} onClick={() => {}}>
                {this.state.role === 'Lab' ? null : (
                  <Checkbox
                    name={report.reportID}
                    checked={this.state[checkname]}
                    onChange={(evt) => {
                      if (
                        !this.state.printChecked.includes(
                          evt.currentTarget.name
                        )
                      ) {
                        var newState = this.state.printChecked.slice();
                        newState.push(evt.currentTarget.name);
                        this.setState({
                          printChecked: newState,
                          [checkname]: true,
                        });
                      } else {
                        newState = this.state.printChecked.slice();
                        for (var i = 0; i < newState.length; i++) {
                          if (newState[i] === evt.currentTarget.name) {
                            newState.splice(i, 1);
                            i--;
                          }
                        }
                        this.setState({
                          printChecked: newState,
                          [checkname]: false,
                        });
                      }
                    }}
                  ></Checkbox>
                )}
              </DataTableCell>

              <DataTableCell
                onClick={() => {
                  this.setState(
                    {
                      patientLabDialog: true,
                      currentResult: report,
                      checkedOnOpen: this.state.checked,
                    },
                    () => {}
                  );
                  this.props.obrRequest(report.reportID);
                }}
              >
                {}
              </DataTableCell>
              {this.state.role === 'Lab' ? null : (
                <DataTableCell>
                  <Button
                    icon="face"
                    onClick={() => {
                      this.props.facesheetRequest(report.patientID, 25, 0);
                      this.props.openDialog('patientInfo');
                    }}
                  ></Button>
                </DataTableCell>
              )}
              {this.state.role === 'Lab' ? (
                <DataTableCell
                  onClick={() => {
                    this.setState(
                      {
                        patientLabDialog: true,
                        currentResult: report,
                        checkedOnOpen: this.state.checked,
                      },
                      () => {}
                    );
                    this.props.obrRequest(report.reportID);
                  }}
                >
                  {report.fileName}
                </DataTableCell>
              ) : (
                <DataTableCell
                  onClick={() => {
                    this.setState(
                      {
                        patientLabDialog: true,
                        currentResult: report,
                        checkedOnOpen: this.state.checked,
                      },
                      () => {}
                    );
                    this.props.obrRequest(report.reportID);
                  }}
                >
                  {report.patientLastName + ', ' + report.patientFirstName}
                </DataTableCell>
              )}
              {this.state.role === 'Lab' ? null : (
                <DataTableCell
                  onClick={() => {
                    this.setState(
                      {
                        patientLabDialog: true,
                        currentResult: report,
                        checkedOnOpen: this.state.checked,
                      },
                      () => {}
                    );
                    this.props.obrRequest(report.reportID);
                  }}
                >
                  {report.facilityName}
                </DataTableCell>
              )}

              <DataTableCell
                onClick={() => {
                  this.setState(
                    {
                      patientLabDialog: true,
                      currentResult: report,
                      checkedOnOpen: this.state.checked,
                    },
                    () => {}
                  );
                  this.props.obrRequest(report.reportID);
                }}
              >
                {rDate.toLocaleDateString()}
              </DataTableCell>
            </DataTableRow>
          );
        });
        return resultData;
      }
    };

    let tablePagination = () => {
      return (
        <TablePagination
          id="resultPag1"
          component="div"
          count={this.state.resultsTotal}
          page={this.state.resultsPage}
          onPageChange={this.handleChangePage}
          rowsPerPage={this.state.resultsPerPage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        ></TablePagination>
      );
    };
    let searchField = () => {
      if (this.state.searchCol === 'Report Date')
        return (
          <div id="searching">
            <TextField
              name="query"
              type="date"
              outlined
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleChangeDate}
              style={{ width: '75%' }}
            />
            <Button
              id="calenderSearch"
              icon="search"
              onClick={() => {
                this.setState({
                  tempSearchCol: this.state.searchCol,
                  tempQuery: this.state.query,
                });
                if (this.props.query === null) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'you must make a selection before searching',
                  });
                } else {
                  this.clearChecks();
                  this.setState(
                    {
                      resultsPage: 0,
                      offset: 0,
                      searching: true,
                      // advSearching: false,
                    },
                    () => {
                      this.props.searchResults(
                        this.state.searchCol,
                        this.state.query,
                        this.state.resultsPerPage,
                        this.state.offset,
                        this.state.sortCol,
                        this.state.sortDir
                      );
                    }
                  );
                }
              }}
            ></Button>
          </div>
        );
      else if (this.state.searchCol === 'Abnormal Results')
        return (
          <div id="abnormalSearch">
            <GridRow>
              <GridCell span={9} id="searching">
                <Select
                  label="Abnormal Result"
                  outlined
                  options={['Yes', 'No']}
                  onChange={(event) => {
                    this.setState({
                      query: event.currentTarget.value,
                    });
                  }}
                ></Select>
              </GridCell>

              <GridCell span={3}>
                <Button
                  style={{ position: 'absolute', top: '4%' }}
                  id="abnormalSearchButton"
                  icon="search"
                  onClick={() => {
                    this.setState({
                      tempSearchCol: this.state.searchCol,
                      tempQuery: this.state.query,
                    });
                    if (this.state.query === '') {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'you must make a selection before searching',
                      });
                    } else {
                      this.clearChecks();
                      this.setState(
                        {
                          searching: true,
                          offset: 0,
                          resultsPage: 0,
                          // advSearching: false,
                        },
                        () => {
                          this.props.searchResults(
                            this.state.searchCol,
                            this.state.query,
                            this.state.resultsPerPage,
                            this.state.offset,
                            this.state.sortCol,
                            this.state.sortDir
                          );
                        }
                      );
                    }
                  }}
                ></Button>
              </GridCell>
            </GridRow>
          </div>
        );
      else if (this.state.searchCol === 'Critical')
        return (
          <div id="abnormalSearch">
            <GridRow>
              <GridCell span={9} id="searching">
                <Select
                  label="Critical Result"
                  outlined
                  options={['Show Only Critical Results']}
                  onChange={(event) => {
                    this.setState({
                      query: event.currentTarget.value,
                    });
                  }}
                ></Select>
              </GridCell>

              <GridCell span={3}>
                <Button
                  style={{ position: 'absolute', top: '4%' }}
                  id="abnormalSearchButton"
                  icon="search"
                  onClick={() => {
                    this.setState({
                      tempSearchCol: this.state.searchCol,
                      tempQuery: this.state.query,
                    });
                    if (this.state.query === '') {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'you must make a selection before searching',
                      });
                    } else {
                      this.clearChecks();
                      this.setState(
                        {
                          searching: true,
                          offset: 0,
                          resultsPage: 0,
                          // advSearching: false,
                        },
                        () => {
                          this.props.searchResults(
                            this.state.searchCol,
                            this.state.query,
                            this.state.resultsPerPage,
                            this.state.offset,
                            this.state.sortCol,
                            this.state.sortDir
                          );
                        }
                      );
                    }
                  }}
                ></Button>
              </GridCell>
            </GridRow>
          </div>
        );
      else if (this.state.searchCol === 'Reviewed')
        return (
          <div id="analyzedSearch">
            <GridRow>
              <GridCell span={9} id="searching">
                <Select
                  label="Reviewed"
                  outlined
                  options={['Reviewed', 'Not Reviewed']}
                  onChange={(event) => {
                    this.setState({
                      query: event.currentTarget.value,
                    });
                  }}
                ></Select>
              </GridCell>

              <GridCell span={3}>
                <Button
                  style={{ position: 'absolute', top: '4%' }}
                  id="analyzedSearchButton"
                  icon="search"
                  onClick={() => {
                    this.setState({
                      tempSearchCol: this.state.searchCol,
                      tempQuery: this.state.query,
                    });
                    if (this.state.query === '') {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'you must make a selection before searching',
                      });
                    } else {
                      this.clearChecks();
                      this.setState(
                        {
                          searching: true,
                          offset: 0,
                          resultsPage: 0,
                          // advSearching: false,
                        },
                        () => {
                          this.props.searchResults(
                            this.state.searchCol,
                            this.state.query,
                            this.state.resultsPerPage,
                            this.state.offset,
                            this.state.sortCol,
                            this.state.sortDir
                          );
                        }
                      );
                    }
                  }}
                ></Button>
              </GridCell>
            </GridRow>
          </div>
        );
      else if (this.state.searchCol === 'Test Name') {
        return (
          <div id="testNameSearch">
            <GridRow>
              <GridCell span={9} id="searching">
                <Select
                  required
                  label="Test Name"
                  outlined
                  value={this.state.query}
                  onChange={(event) => {
                    this.setState({
                      query: event.currentTarget.value,
                    });
                  }}
                >
                  {this.state.testNameList.map((testName) => {
                    return <option value={testName}>{testName}</option>;
                  })}
                </Select>
              </GridCell>

              <GridCell span={3}>
                <Button
                  style={{ paddingTop: '20%' }}
                  id="testNameSearchButton"
                  icon="search"
                  onClick={() => {
                    this.setState({
                      tempSearchCol: this.state.searchCol,
                      tempQuery: this.state.query,
                    });
                    if (this.state.query === '') {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'you must make a selection before searching',
                      });
                    } else {
                      this.clearChecks();
                      this.setState(
                        {
                          searching: true,
                          offset: 0,
                          resultsPage: 0,
                          // advSearching: false,
                        },
                        () => {
                          this.props.searchResults(
                            this.state.searchCol,
                            this.state.query,
                            this.state.resultsPerPage,
                            this.state.offset,
                            this.state.sortCol,
                            this.state.sortDir
                          );
                        }
                      );
                    }
                  }}
                ></Button>
              </GridCell>
            </GridRow>
          </div>
        );
      } else if (this.state.searchCol === 'Subtest') {
        return (
          <div id="subtestSearch">
            <GridRow>
              <GridCell span={9} id="searching">
                <Select
                  label="Subtest Name"
                  outlined
                  value={this.state.query}
                  onChange={(event) => {
                    this.setState({
                      query: event.currentTarget.value,
                    });
                  }}
                >
                  {this.state.subtestList.map((subtest) => {
                    return <option value={subtest}>{subtest}</option>;
                  })}
                </Select>
              </GridCell>

              <GridCell span={3}>
                <Button
                  style={{ position: 'absolute', top: '4%' }}
                  id="subtestNameSearchButton"
                  icon="search"
                  onClick={() => {
                    this.setState({
                      tempSearchCol: this.state.searchCol,
                      tempQuery: this.state.query,
                    });
                    if (this.state.query === '') {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'you must make a selection before searching',
                      });
                    } else {
                      this.clearChecks();
                      this.setState(
                        {
                          searching: true,
                          offset: 0,
                          resultsPage: 0,
                          // advSearching: false,
                        },
                        () => {
                          this.props.searchResults(
                            this.state.searchCol,
                            this.state.query,
                            this.state.resultsPerPage,
                            this.state.offset,
                            this.state.sortCol,
                            this.state.sortDir
                          );
                        }
                      );
                    }
                  }}
                ></Button>
              </GridCell>
            </GridRow>
          </div>
        );
      } else {
        return (
          <GridRow>
            <GridCell span={9} id="searching">
              <Select
                label="facilitySearch"
                outlined
                value={this.state.query}
                onChange={(event) => {
                  this.setState({
                    query: event.currentTarget.value,
                  });
                }}
              >
                {this.state.facilityList.map((facility) => {
                  return (
                    <option
                      value={facility}
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                      {facility}
                    </option>
                  );
                })}
              </Select>
            </GridCell>

            <GridCell span={3}>
              <Button
                style={{ position: 'absolute', top: '4%' }}
                id="facilitySearchButton"
                icon="search"
                onClick={() => {
                  this.setState({
                    tempSearchCol: this.state.searchCol,
                    tempQuery: this.state.query,
                  });
                  if (this.state.query === '') {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'you must make a selection before searching',
                    });
                  } else {
                    this.clearChecks();
                    this.setState(
                      {
                        searching: true,
                        offset: 0,
                        resultsPage: 0,
                        // advSearching: false,
                      },
                      () => {
                        this.props.searchResults(
                          this.state.searchCol,
                          this.state.query,
                          this.state.resultsPerPage,
                          this.state.offset,
                          this.state.sortCol,
                          this.state.sortDir
                        );
                      }
                    );
                  }
                }}
              ></Button>
            </GridCell>
          </GridRow>
        );
      }
    };
    let advancedSearchField = (x, i) => {
      if (x.dropVal === 'Report Date')
        return (
          <div id="searchDate">
            <form noValidate>
              <TextField
                id="searchDateCalender"
                name="searchDate"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  this.handleAdvChange(event, x, i);
                }}
              />
            </form>
          </div>
        );
      else if (x.dropVal === 'Abnormal Results')
        return (
          <div id="abnormalSearch">
            <Select
              label="Abnormal Result"
              outlined
              options={['Yes', 'No']}
              onChange={(event) => {
                this.handleAdvChange(event, x, i);
              }}
            ></Select>
          </div>
        );
      else if (x.dropVal === 'Critical')
        return (
          <div id="abnormalSearch">
            <Select
              label="Critical Result"
              outlined
              options={['Show Only Critical Results']}
              onChange={(event) => {
                this.handleAdvChange(event, x, i);
              }}
            ></Select>
          </div>
        );
      else if (x.dropVal === 'Reviewed')
        return (
          <div id="analyzedSearch">
            <Select
              label="Reviewed"
              outlined
              options={['Reviewed', 'Not Reviewed']}
              onChange={(event) => {
                this.handleAdvChange(event, x, i);
              }}
            ></Select>
          </div>
        );
      else if (x.dropVal === 'Test Name') {
        return (
          <div id="testNameSearch">
            <Select
              label="Test Name"
              outlined
              onChange={(event) => {
                this.handleAdvChange(event, x, i);
              }}
            >
              {this.state.testNameList.map((testName) => {
                return <option value={testName}>{testName}</option>;
              })}
            </Select>
          </div>
        );
      } else if (x.dropVal === 'Subtest') {
        return (
          <div id="subtestSearch">
            <Select
              label="Subtest Name"
              outlined
              onChange={(event) => {
                this.handleAdvChange(event, x, i);
              }}
            >
              {this.state.subtestList.map((subtest) => {
                return <option value={subtest}>{subtest}</option>;
              })}
            </Select>
          </div>
        );
      } else {
        return (
          <Select
            label="facilitySearch"
            outlined
            onChange={(event) => {
              this.handleAdvChange(event, x, i);
            }}
          >
            {this.state.facilityList.map((facility) => {
              if (facility.length > 25) {
                return (
                  <option value={facility}>
                    {/* {facility.slice(0, 22) + '...'} */}
                    {facility}
                  </option>
                );
              } else {
                return <option value={facility}>{facility}</option>;
              }
            })}
          </Select>
        );
      }
    };

    return (
      <div>
        {patientLabDialog()}
        {printDialog()}
        {advancedSearchDialog()}
        {obrNoteDialog()}
        {obxNoteDialog()}

        {this.state.patientInfoDialog &&
        this.state.currentPatient.length !== 0 ? (
          <PatientInfoComponent
            currentPatient={this.state.currentPatient}
            dmv={this.state.dmv}
            patientInfoDialog={this.state.patientInfoDialog}
          ></PatientInfoComponent>
        ) : null}
        {this.state.role === 'Lab' ? (
          <div id="labHead1">
            <Typography
              use="headline4"
              style={{ top: '90px', position: 'absolute', left: '20px' }}
            >
              {' '}
              Uploaded Results{' '}
            </Typography>
          </div>
        ) : (
          <Grid>
            <GridRow>
              <GridCell span={5}>
                <div></div>
              </GridCell>

              <GridCell span={3}>
                <div id="searchCol">
                  <Select
                    label="Field"
                    outlined
                    options={[
                      'Abnormal Results',
                      'Critical',
                      'Facility',
                      'Patient Allergies',
                      'Patient Last Name',
                      'Report Date',
                      'Reviewed',
                      'Subtest',
                      'Test Name',
                    ]}
                    value={this.state.searchCol}
                    name="searchCol"
                    onChange={(event) => {
                      let value = event.currentTarget.value;
                      this.setState(
                        {
                          query: '',
                        },
                        () => {
                          this.setState({ searchCol: value });
                        }
                      );
                    }}
                  />
                </div>
              </GridCell>
              <GridCell span={4}>
                {this.state.searchCol === 'Report Date' ||
                this.state.searchCol === 'Abnormal Results' ||
                this.state.searchCol === 'Reviewed' ||
                this.state.searchCol === 'Test Name' ||
                this.state.searchCol === 'Subtest' ||
                this.state.searchCol === 'Facility' ||
                this.state.searchCol === 'Critical' ? (
                  <div>{searchField()}</div>
                ) : (
                  <div id="searching">
                    <form>
                      <TextField
                        style={{ width: '74%' }}
                        outlined
                        label="Search Term"
                        trailingIcon={{
                          icon: 'search',
                          tabIndex: 0,
                          onClick: () => {
                            this.setState({
                              tempSearchCol: this.state.searchCol,
                              tempQuery: this.state.query,
                            });
                            if (this.state.query === '') {
                              Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'you must make a selection before searching',
                              });
                            } else {
                              this.clearChecks();

                              this.props.searchResults(
                                this.state.searchCol,
                                this.state.query,
                                this.state.resultsPerPage,
                                this.state.offset,
                                this.state.sortCol,
                                this.state.sortDir
                              );
                            }
                          },
                        }}
                        name="query"
                        value={this.state.query}
                        onChange={this.handleChange}
                        onKeyDown={this.keyPress}
                      />
                    </form>
                  </div>
                )}
              </GridCell>
            </GridRow>
            <GridRow>
              <GridCell span={6}>
                <div id="labHead">
                  <Typography use="headline4"> Lab Results </Typography>
                </div>
              </GridCell>
              <GridCell span={3}>
                <Button
                  style={{ position: 'absolute', right: '35%' }}
                  icon="search"
                  onClick={() => this.setState({ advancedSearchDialog: true })}
                >
                  Advanced Search
                </Button>
              </GridCell>
              <GridCell span={3}>
                <Button
                  style={{ left: '79%', width: '15%' }}
                  id="clearSearch"
                  icon="clear"
                  label="Clear Search"
                  onClick={() => {
                    this.clearSearch();
                  }}
                ></Button>
              </GridCell>
            </GridRow>
          </Grid>
        )}

        <div id="resultsTable">
          <DataTable
            id="resultTable"
            stickyRows={1}
            stickyColumns={1}
            style={{ backgroundColor: 'white' }}
          >
            {this.props.searchGenerating ? (
              <CircularProgress size={75} id="searchLoadBar"></CircularProgress>
            ) : null}
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableHeadCell>
                    <Checkbox
                      checked={this.state.checkAll}
                      onClick={(evt) => {
                        this.checkAll(evt);
                      }}
                    ></Checkbox>
                  </DataTableHeadCell>
                  <DataTableHeadCell
                    style={{ width: '10px' }}
                  ></DataTableHeadCell>
                  {this.state.role === 'Lab' ? null : (
                    <DataTableHeadCell
                      style={{
                        borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                        width: '10px',
                      }}
                    >
                      Patient Info
                    </DataTableHeadCell>
                  )}
                  {this.state.role === 'Lab' ? (
                    <DataTableHeadCell
                      style={{
                        borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                      }}
                    >
                      File Name
                    </DataTableHeadCell>
                  ) : (
                    <DataTableHeadCell
                      sort={this.state.patientNameSort}
                      onSortChange={(sortDir) => {
                        this.setState({
                          patientNameSort: sortDir,
                          reportIDSort: null,
                          reportDateSort: null,
                          facilitySort: null,
                        });
                        this.sortData(sortDir, 'patientName');
                      }}
                      style={{
                        borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                      }}
                    >
                      Patient Name
                    </DataTableHeadCell>
                  )}
                  {this.state.role === 'Lab' ? null : (
                    <DataTableHeadCell
                      sort={this.state.facilitySort}
                      onSortChange={(sortDir) => {
                        this.setState({
                          facilitySort: sortDir,
                          reportIDSort: null,
                          patientNameSort: null,
                          reportDateSort: null,
                        });
                        this.sortData(sortDir, 'facility');
                      }}
                      style={{
                        borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                      }}
                    >
                      Facility
                    </DataTableHeadCell>
                  )}

                  {this.state.role === 'Lab' ? (
                    <DataTableHeadCell
                      sort={this.state.reportDateSort}
                      onSortChange={(sortDir) => {
                        this.setState({
                          reportDateSort: sortDir,
                          reportIDSort: null,
                          patientNameSort: null,
                          facilitySort: null,
                        });
                        this.sortData(sortDir, 'reportDate');
                      }}
                      style={{
                        borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                      }}
                    >
                      Upload Date
                    </DataTableHeadCell>
                  ) : (
                    <DataTableHeadCell
                      sort={this.state.reportDateSort}
                      onSortChange={(sortDir) => {
                        this.setState({
                          reportDateSort: sortDir,
                          reportIDSort: null,
                          patientNameSort: null,
                          facilitySort: null,
                        });
                        this.sortData(sortDir, 'reportDate');
                      }}
                      style={{
                        borderRight: ' 1px solid rgb(0 0 0 / 12%)',
                      }}
                    >
                      Report Date
                    </DataTableHeadCell>
                  )}
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>{resultTableInfo()}</DataTableBody>
            </DataTableContent>
          </DataTable>
          <div>{tablePagination()}</div>
        </div>

        {this.state.role === 'Lab' ? null : (
          <Button
            disabled={this.props.printLoading}
            id="printDialogButton"
            label="Print Reports"
            icon="print"
            outlined
            onClick={() => {
              if (this.state.printChecked.length === 0) {
                Swal.fire({
                  icon: 'error',
                  text: 'Please select reports to print',
                });
              }
              this.props.printReports(0, this.state.printChecked);
              this.clearChecks();
            }}
          ></Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    decodedJWT: state.login.decodedJWT,
    results: state.result.results,
    reports: state.result.reports,
    obrResults: state.result.obrResults,
    printReports: state.result.printReports,
    facilities: state.result.facilities,
    subtest: state.result.subtest,
    testName: state.result.testName,
    patientQuery: state.result.patientQuery,
    patientQueryID: state.result.patientQueryID,
    patientQueryFacID: state.result.patientQueryFacID,
    resultTotal: state.result.resultTotal,
    diagnosis: state.patient.diagnosis,
    vitals: state.patient.vitals,
    medications: state.patient.medications,
    facesheet: state.patient.facesheet,
    printLoading: state.result.printLoading,
    dmv: state.patient.dmv,
    searchCol: state.searchCol,
    resultClicked: state.result.resultClicked,
    patientInfoDialog: state.result.patientInfoDialog,
    searchGenerating: state.result.searchGenerating,
    role: state.login.decodedJWT.role,
    subtestQuery: state.result.subtestQuery,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    queryResults: (limit, offset, sort, dir) =>
      dispatch(resultRequest(limit, offset, sort, dir)),
    patientLabResults: (
      patientID,
      facilityID,
      limit,
      offset,
      sort,
      direction
    ) =>
      dispatch(
        patientLabResults(patientID, facilityID, limit, offset, sort, direction)
      ),
    searchResults: (col, term, limit, offset, sort, dir) =>
      dispatch(searchRequest(col, term, limit, offset, sort, dir)),
    obrRequest: (param) => dispatch(obrRequest(param)),
    printReports: (offset, param) => dispatch(printReports(offset, param)),
    getFacilities: () => dispatch(getFacilityRequest()),
    analyzedReports: (col, param) => dispatch(analyzedReports(col, param)),
    getSubtests: () => dispatch(getSubtestRequest()),
    getTestName: () => dispatch(getTestNameRequest()),
    advancedSearch: (col, limit, offset, sort, dir) =>
      dispatch(advancedSearch(col, limit, offset, sort, dir)),
    facesheetRequest: (term, count, offset) =>
      dispatch(facesheetRequest(term, count, offset)),
    dmvRequest: (term, count) => dispatch(dmvRequest(term, count)),
    updateDMVRequest: (json) => dispatch(updateDMVRequest(json)),
    resultClickedDone: () => dispatch(resultClickedDone()),
    openDialog: (dialog) => dispatch(openDialog(dialog)),
    clearSearch: () => dispatch(clearSearch()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultComponent);
