import {
  UPDATE_FACILITY_FAIL,
  UPDATE_FACILITY_SUCCESS,
  MERGE_FACILITIES_FAIL,
  MERGE_FACILITIES_SUCCESS,
  FACILITY_SEARCH_FAIL,
  FACILITY_SEARCH_SUCCESS,
  ADMIN_FACILITYLIST_SUCCESS,
  ADMIN_FACILITYLIST_FAIL,
} from '../constants/facilityConstants';
import { getFacilityRequest } from './userActions';
import Swal from 'sweetalert2';

export const updateFacilitySuccess = (facilities) => ({
  type: UPDATE_FACILITY_SUCCESS,
  facilities: facilities,
});

export const updateFacilityFail = () => ({
  type: UPDATE_FACILITY_FAIL,
});

export const mergeFacilitiesSuccess = () => {
  Swal.fire({
    icon: 'success',
    text: 'Successfully merged facilities',
  });
  return {
    type: MERGE_FACILITIES_SUCCESS,
  };
};
export const mergeFacilitiesFail = () => ({
  type: MERGE_FACILITIES_FAIL,
});
export const facilitySearchSuccess = (results) => ({
  type: FACILITY_SEARCH_SUCCESS,
  facilityResults: JSON.parse(results.results),
  //count: results.count,
  facilityTotal: JSON.parse(results.count),
});
export const facilitySearchFail = () => ({
  type: FACILITY_SEARCH_FAIL,
});
export const queryFacilityListSuccess = (facilityList) => ({
  type: ADMIN_FACILITYLIST_SUCCESS,
  facilityList: JSON.parse(facilityList.results),
  facilityTotal: JSON.parse(facilityList.count),
});

export const queryFacilityListFail = (error) => ({
  type: ADMIN_FACILITYLIST_FAIL,
});
export const loadFacilityRequest = (limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/getFacilities?limit=' +
        limit +
        '&offset=' +
        offset
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(queryFacilityListSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(queryFacilityListFail(error));
      });
  };
};
export const searchRequest = (col, term, limit, offset, sort, dir) => {
  return (dispatch) => {
    let url = null;
    let where = null;

    if (col === 'Facility Name') {
      where = 'facilityName="' + encodeURIComponent(term) + '"';
    } else {
      where = 'facilityState="' + encodeURIComponent(term) + '"';
    }

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/webAdmin/searchFacilities?param=' +
        where +
        '&limit=' +
        limit +
        '&offset=' +
        offset
    );
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        return response.json();
      })
      .then((searchResult) => {
        dispatch(facilitySearchSuccess(searchResult));
      })
      .catch((error) => {
        dispatch(facilitySearchFail(error));
      });
  };
};

export const updateFacilityRequest = (json) => {
  return (dispatch) => {
    let url = new URL(
      process.env.REACT_APP_API_URL + '/webAdmin/updateFacility'
    );
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(json),
    })
      .then((response) => {
        dispatch(getFacilityRequest());
      })
      .catch((error) => {
        dispatch(updateFacilityFail(error));
      });
  };
};
export const mergeFacilities = (fac1, fac2) => {
  return (dispatch) => {
    let url = null;
    let where = null;
    let col = null;
    where = 'patientFacilityID="' + fac2 + '"';
    col = 'patientFacilityID="' + fac1 + '"';

    url = new URL(
      process.env.REACT_APP_API_URL +
        '/data/mergeFacilities?col=' +
        encodeURIComponent(fac1) +
        '&where=' +
        encodeURIComponent(fac2)
    );

    fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include',
    })
      .then((searchResult) => {
        dispatch(mergeFacilitiesSuccess(searchResult));
        dispatch(getFacilityRequest());
      })
      .catch((error) => {
        dispatch(mergeFacilitiesFail(error));
      });
  };
};
