import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getUsersRequest,
  createFacilityRequest,
  deleteUserRequest,
} from '../actions/userActions';
import {
  ADMIN_TSALTA_ADMIN,
  ADMIN_TSALTA_USER,
  ADMIN_TSALTA_FACADMIN,
  ADMIN_TSALTA_FACUSER,
  ADMIN_TSALTA_LAB,
} from '../constants/userConstants';
import {
  updateUserRequest,
  getFacilityRequest,
  addUserRequest,
} from '../actions/userActions';
import {
  getLabsRequest,
} from '../actions/labManagementActions';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { DataTable, DataTableCell } from '@rmwc/data-table';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogButton,
  DialogTitle,
} from '@rmwc/dialog';
import '@rmwc/dialog/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemIcon,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';

import '../css/UserComponent.css';
import { Button } from '@rmwc/button';
import FormControl from '@material-ui/core/FormControl';
import { Select } from '@rmwc/select';
import { Typography } from '@rmwc/typography';
import Swal from 'sweetalert2';
import List from '@material-ui/core/List';
import { Checkbox } from 'rmwc';

import { getGroupsRequest } from '../actions/groupActions';

class UserComponent extends Component {
  constructor() {
    super();
    this.state = {
      facilities: [],
      regions: [],
      webUsers: [],
      webUserInfoDialog: false,
      currentWebUser: [],
      changeUserInfoDialog: false,
      editUserDialogOpen: false,
      currentPassword: '',
      newPassword: '',
      facilityState: '',
      facilitiesSelected: [],
      username: '',
      userFirstName: '',
      userLastName: '',
      userAddress: '',
      userCity: '',
      userState: '',
      userZipcode: '',
      userDOB: '',
      userPhoneNum: '',
      userRole: '',
      rightList: [],
      rightChecked: [],
      leftList: [],
      leftChecked: [],
      groupList: [],
      tempList: [],
      editingUser: false,
      userInfoDialogOpen: false,
      updatePassDialogOpen: false,
      userGroup: '1',
      userLab: 0,
    };
  }
  static getDerivedStateFromProps(props, state) {
    let groupList = [''];
    props.groups.forEach((group) => {
      groupList.push(group);
    });
    let facilityList = [];
    props.facilities.forEach((facility) => {
      if (!facilityList.includes(facility)) {
        facilityList.push(facility);
      }
    });
    let labList = [];
    props.labs.forEach((lab) => {
      labList.push(lab);
    });
    if (state.editingUser) {
      return {
        ...state,
      };
    } else {
      return {
        ...state,
        webUsers: props.webUsers,
        facilities: props.facilities,
        leftList: props.facilities,
        groups: props.groups,
        groupList: groupList,
        facilityList: facilityList,
        labList: labList,
      };
    }
  }

  UNSAFE_componentWillMount() {
    //check if the user is attempting to manually navigate to the admin panel
    if (
      this.props.role !== 'administrator' ||
      this.props.role !== ADMIN_TSALTA_ADMIN
    ) {
      this.props.history.push('/app');
    }
  }
  componentDidMount() {
    this.props.getUsers();
    this.props.getFacilities();
    this.props.getGroups();
    this.props.getLabsRequest();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({ [event.target.name]: value }, () => { });
  };
  isEmpty(value) {
    if (value == null || value.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  addUser = () => {
    let userJson = {
      username: this.state.username,
      password: this.state.editPassword,
      role: this.state.userRole,
      userFirstName: this.state.userFirstName,
      userLastName: this.state.userLastName,
      facilities: this.state.rightList,
      userGroups: this.state.userGroup,
      userAddress: this.state.userAddress,
      userCity: this.state.userCity,
      userState: this.state.userState,
      userZipcode: this.state.userZipcode,
      userDOB: this.state.userDOB,
      userPhoneNum: this.state.userPhoneNum,
      labID: this.state.userLab,
    };
    this.props.addUser(userJson);
  };

  updateUser = () => {
    let currentWebUser = this.state.currentWebUser;
    let userJson = {
      username: currentWebUser.username,
      password: this.state.editPassword,
      role: this.state.userRole,
      userFirstName: this.state.userFirstName,
      userLastName: this.state.userLastName,
      facilities: this.state.rightList,
      userGroups: this.state.userGroup,
      userAddress: this.state.userAddress,
      userCity: this.state.userCity,
      userState: this.state.userState,
      userZipcode: this.state.userZipcode,
      userDOB: this.state.userDOB,
      userPhoneNum: this.state.userPhoneNum,
    };
    this.props.updateUser(userJson);
  };

  moveRight = () => {
    let rightJson = [];
    let rightList = this.state.rightList;
    let leftList = this.state.leftList;
    this.state.leftChecked.forEach((item) => {
      rightJson.push(JSON.parse(item));
    });
    rightJson.forEach((item) => {
      let checkname = 'check' + item.facilityID;
      leftList = leftList.filter((facility) => {
        return facility.facilityID !== item.facilityID;
      });
      rightList.push(item);
      this.setState({ [checkname]: false });
    });
    this.setState({
      leftList: leftList,
      rightList: rightList,
      leftChecked: [],
    });
  };

  moveLeft = () => {
    let leftJson = [];
    let rightList = this.state.rightList;
    let leftList = this.state.facilityList;
    this.state.rightChecked.forEach((item) => {
      leftJson.push(JSON.parse(item));
    });
    leftJson.forEach((item) => {
      let checkname = 'check' + item.facilityID;
      rightList = rightList.filter((facility) => {
        return facility.facilityID !== item.facilityID;
      });
      leftList.push(item);
      this.setState({ [checkname]: false });
    });
    this.setState({
      leftList: leftList,
      rightList: rightList,
      rightChecked: [],
    });
  };
  checkLeft(evt, checkname) {
    if (evt.target.checked) {
      let lftCheck = this.state.leftChecked;
      lftCheck.push(evt.target.value);
      this.setState({ leftChecked: lftCheck, [checkname]: true });
    } else {
      let lftCheck = this.state.leftChecked;
      lftCheck = lftCheck.filter((facility) => {
        let fac = JSON.parse(facility);
        let val = JSON.parse(evt.target.value);
        return fac.facilityID !== val.facilityID;
      });
      this.setState({ leftChecked: lftCheck, [checkname]: false });
    }
  }
  checkRight(evt, checkname) {
    if (evt.target.checked) {
      let rtCheck = this.state.rightChecked;
      rtCheck.push(evt.target.value);
      this.setState({ rightChecked: rtCheck, [checkname]: true });
    } else {
      let rtCheck = this.state.rightChecked;
      rtCheck = rtCheck.filter((facility) => {
        let fac = JSON.parse(facility);
        let val = JSON.parse(evt.target.value);
        return fac.facilityID !== val.facilityID;
      });
      this.setState({ rightChecked: rtCheck, [checkname]: false });
    }
  }

  render() {
    const states = [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virgin Island',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ];
    let webUserInfoDialog = () => {
      return (
        <Dialog
          onClick={() => { }}
          open={this.state.webUserInfoDialog}
          onClose={(evt) => {
            this.setState({ webUserInfoDialog: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogContent>
            <div>
              <Typography use="headline4">User Information</Typography>
            </div>
            {webUserDetails()}
          </DialogContent>
          <DialogActions>
            <DialogButton
              icon="update"
              unelevated
              onClick={() => {
                this.setState({ changeUserInfoDialog: true });
              }}
            >
              Change User Password
            </DialogButton>
            <DialogButton outlined icon="close" action="close">
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    let changeUserInfoDialog = () => {
      return (
        <Dialog
          open={this.state.changeUserInfoDialog}
          onClose={(evt) => {
            this.setState({ changeUserInfoDialog: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogContent>
            <div>
              <TextField
                outlined
                label="Enter current password"
                name="currentPassword"
                value={this.state.currentPassword}
                onChange={this.handleChange.bind(this)}
              />
            </div>
            <div> </div>
            <div>
              <TextField
                outlined
                label="Enter new password"
                name="newPassword"
                value={this.state.newPassword}
                onChange={this.handleChange.bind(this)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <DialogButton
              action="update"
              icon="update"
              outlined
              onClick={() => {
                this.setState({ changeUserInfoDialog: false });
              }}
            >
              Update
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };
    const roles = [
      '',
      ADMIN_TSALTA_ADMIN,
      ADMIN_TSALTA_USER,
      ADMIN_TSALTA_FACADMIN,
      ADMIN_TSALTA_FACUSER,
      ADMIN_TSALTA_LAB,
    ];

    let addUserDialog = () => {
      let leftList = this.state.leftList;
      let rightList = this.state.rightList;
      let webUser = this.state.currentWebUser;
      let groupList = this.state.groupList;
      let labList = this.state.labList;
      return (
        <Dialog
          open={this.state.addUserDialogOpen}
          onOpen={() => {
            this.setState({ editingUser: true });
          }}
          onClose={(evt) => {
            // this.setState({ addUserDialogOpen: false, editingUser: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            Add User
          </DialogTitle>
          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <Grid>
              <GridRow>
                <GridCell span={3}>
                  <Typography>Username:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    placeholder="Username"
                    outlined
                    name="username"
                    value={this.state.username}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>New Password:</Typography>
                </GridCell>
                <GridCell span={9}>
                  <TextField
                    style={{ width: '500px' }}
                    type="password"
                    placeholder="Password"
                    outlined
                    name="editPassword"
                    value={this.state.editPassword}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>
                  <Typography>User Full Name:</Typography>
                </GridCell>
                <GridCell span={3}>
                  <TextField
                    style={{ width: '240px' }}
                    placeholder="First Name"
                    outlined
                    name="userFirstName"
                    value={this.state.userFirstName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
                <GridCell span={3}>
                  <TextField
                    style={{ width: '240px', left: '15%' }}
                    placeholder="Last Name"
                    outlined
                    name="userLastName"
                    value={this.state.userLastName}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>User Role:</GridCell>
                <GridCell span={9}>
                  <FormControl>
                    <Select
                      placeholder="User Role"
                      outlined
                      native
                      displayEmpty
                      value={this.state.userRole}
                      onChange={(event) =>
                        this.setState({ userRole: event.currentTarget.value })
                      }
                      inputProps={{
                        name: 'userRole',
                      }}
                    >
                      {roles.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </Select>
                    <div style={{ paddingBottom: '10px' }}></div>
                  </FormControl>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>User Phone Number:</GridCell>
                <GridCell span={9}>
                  <TextField
                    placeholder="Phone Number"
                    outlined
                    name="userPhoneNum"
                    value={this.state.userPhoneNum}
                    onChange={(e) => this.handleChange(e)}
                  ></TextField>
                </GridCell>
              </GridRow>

              <GridRow>
                <GridCell span={3}>User Group:</GridCell>
                <GridCell span={9}>
                  <FormControl>
                    <Select
                      disabled={this.state.userRole === 'Lab'}
                      id="groupSelect"
                      outlined
                      native
                      displayEmpty
                      onChange={(event) =>
                        this.setState({ userGroup: event.currentTarget.value })
                      }
                      labelId="User Group"
                      value={this.state.userGroup}
                      inputProps={{
                        name: 'userGroup',
                      }}
                    >
                      {groupList.map((state) => (
                        <option key={state.groupID} value={state.groupID}>
                          {state.groupName}
                        </option>
                      ))}
                    </Select>
                    <div style={{ paddingBottom: '10px' }}></div>
                  </FormControl>
                </GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={3}>User Lab:</GridCell>
                <GridCell span={9}>
                  <FormControl>
                    <Select
                      disabled={this.state.userRole !== 'Lab'}
                      placeholder="User Lab"
                      outlined
                      native
                      displayEmpty
                      value={this.state.userLab}
                      onChange={(event) =>
                        this.setState({ userLab: event.currentTarget.value })
                      }
                      inputProps={{
                        name: 'userLab',
                      }}
                    >
                      {labList.map((lab) => (
                        <option key={lab} value={lab.labID}>
                          {lab.labName}
                        </option>
                      ))}
                    </Select>
                    <div style={{ paddingBottom: '10px' }}></div>
                  </FormControl>
                </GridCell>
              </GridRow>
            </Grid>
            {this.state.userRole !== 'Lab' ? (<Grid id="groupSelector">
              <GridCell span={5}>
                <Grid id="lGrid" item>
                  <Paper id="lPaper" variant="outlined">
                    <List dense component="div" role="list">
                      {leftList != null
                        ? leftList.map((facility) => {
                          let checkname = 'check' + facility.facilityID;
                          return (
                            <ListItem role="listitem">
                              <ListItemIcon>
                                <Checkbox
                                  disabled={this.state.userRole === 'Lab'}
                                  name={facility.facilityID}
                                  tabIndex={-1}
                                  disableRipple
                                  checked={this.state[checkname]}
                                  value={JSON.stringify(facility)}
                                  onChange={(evt) => {
                                    this.checkLeft(evt, checkname);
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={facility.facilityName} />
                            </ListItem>
                          );
                        })
                        : null}
                      <ListItem />
                    </List>
                  </Paper>
                </Grid>
              </GridCell>
              <GridCell span={2}>
                <div id="selectorButtons">
                  <div>
                    <Button
                      outlined
                      size="small"
                      onClick={() => {
                        this.moveRight();
                      }}
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                  </div>
                  <div>
                    <Button
                      outlined
                      size="small"
                      onClick={() => {
                        this.moveLeft();
                      }}
                    >
                      &lt;
                    </Button>
                  </div>
                </div>
              </GridCell>
              <GridCell span={5}>
                <Grid id="rGrid" item>
                  <Paper id="rPaper" variant="outlined">
                    <List dense component="div" role="list">
                      {rightList.map((facility) => {
                        let checkname = 'check' + facility.facilityID;
                        return (
                          <ListItem role="listitem">
                            <ListItemIcon>
                              <Checkbox
                                tabIndex={-1}
                                disableRipple
                                value={JSON.stringify(facility)}
                                checked={this.state[checkname]}
                                onChange={(evt) => {
                                  this.checkRight(evt, checkname);
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText primary={facility.facilityName} />
                          </ListItem>
                        );
                      })}
                      <ListItem />
                    </List>
                  </Paper>
                </Grid>
              </GridCell>
            </Grid>) : (null)}
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              unelevated
              icon="save"
              onClick={() => {
                if (
                  this.isEmpty(this.state.userRole) ||
                  this.isEmpty(this.state.username) ||
                  this.isEmpty(this.state.editPassword)
                ) {
                  Swal.fire({
                    icon: 'error',
                    text: 'Please fill out username, role, and password for new user.',
                  });
                } else {
                  this.addUser();
                  this.setState({
                    addUserDialogOpen: false,
                    editingUser: false,
                  });
                }
                this.setState({
                  currentWebUser: webUser,
                  editingUser: false,
                  rightList: [],
                  userFirstName: '',
                  userLastName: '',
                  username: '',
                  editPassword: '',
                  userAddress: '',
                  userCity: '',
                  userState: '',
                  userZipcode: '',
                  userRole: '',
                  userPhoneNum: '',
                  userGroup: 1,
                });
              }}
            >
              Save
            </DialogButton>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({ addUserDialogOpen: false, editingUser: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let editUserDialog = () => {
      let leftList = this.state.facilityList;
      let rightList = this.state.rightList;
      let webUser = this.state.currentWebUser;
      let groupList = this.state.groupList;
      let labList = this.state.labList;
      for (let i = 0; i < rightList.length; i++) {
        for (let j = 0; j < leftList.length; j++) {
          if (leftList[j].facilityName === rightList[i].facilityName) {
            leftList.splice(j, 1);
          }
        }
      }
      return (
        <Dialog
          open={this.state.editUserDialogOpen}
          onClose={(evt) => {
            this.setState({ editUserDialogOpen: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogTitle
            id="addTitle"
            style={{
              borderTop: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            {'Modify User "' + this.state.username + '"'}
          </DialogTitle>

          <DialogContent
            style={{
              borderLeft: 'solid',
              borderRight: 'solid',
              borderColor: 'dodgerblue',
            }}
          >
            <div>
              <Grid>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>User Full Name:</Typography>
                  </GridCell>
                  <GridCell span={4.5}>
                    <TextField
                      style={{ width: '300px' }}
                      placeholder="First Name"
                      outlined
                      name="userFirstName"
                      value={this.state.userFirstName}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                  <GridCell span={4.5}>
                    <TextField
                      style={{ width: '300px' }}
                      placeholder="Last Name"
                      outlined
                      name="userLastName"
                      value={this.state.userLastName}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>
                {/* <GridRow>
                  <GridCell span={3}>
                    <Typography>New Password:</Typography>
                  </GridCell>
                  <GridCell span={4.5}>
                    <TextField
                      style={{ width: '300px' }}
                      type="password"
                      placeholder="Password"
                      outlined
                      name="editPassword"
                      value={this.state.editPassword}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                  <GridCell span={4.5}>
                    <TextField
                      style={{ width: '300px' }}
                      type="password"
                      placeholder="Reenter Password"
                      outlined
                      name="editPassword"
                      value={this.state.editPassword}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow> */}
                <GridRow>
                  <GridCell span={3}>User Role:</GridCell>
                  <GridCell span={9}>
                    <FormControl>
                      <Select
                        placeholder="User Role"
                        outlined
                        native
                        displayEmpty
                        value={this.state.userRole}
                        onChange={(event) =>
                          this.setState({ userRole: event.currentTarget.value })
                        }
                        labelId="User Role"
                        inputProps={{
                          name: 'userRole',
                        }}
                      >
                        {roles.map((state) => {
                          return (
                            <option key={state} value={state}>
                              {state}
                            </option>
                          );
                        })}
                      </Select>
                      <div style={{ paddingBottom: '10px' }}></div>
                    </FormControl>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>Phone Number:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <TextField
                      placeholder="Phone Number"
                      outlined
                      name="userPhoneNum"
                      value={this.state.userPhoneNum}
                      onChange={(e) => this.handleChange(e)}
                    ></TextField>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}>
                    <Typography>Group:</Typography>
                  </GridCell>
                  <GridCell span={9}>
                    <FormControl>
                      {/* <InputLabel id="User Group">User Group:</InputLabel> */}
                      <Select
                        // id="groupSelect"
                        placeholder="User Group"
                        outlined
                        // value={this.state.userGroup}
                        native
                        displayEmpty
                        onChange={(event) =>
                          this.setState({
                            userGroup: event.currentTarget.value,
                          })
                        }
                        labelId="User Group"
                        inputProps={{
                          name: 'userGroup',
                        }}
                      >
                        {groupList.map((state) => {
                          return (
                            <option key={state.groupID} value={state.groupID}>
                              {state.groupName}
                            </option>
                          );
                        })}
                      </Select>
                      <div style={{ paddingBottom: '10px' }}></div>
                    </FormControl>
                  </GridCell>
                </GridRow>
                <GridRow>
                  <GridCell span={3}>User Lab:</GridCell>
                  <GridCell span={9}>
                    <FormControl>
                      <Select
                        disabled={this.state.userRole !== 'Lab'}
                        placeholder="User Lab"
                        outlined
                        native
                        displayEmpty
                        value={this.state.userLab}
                        onChange={(event) =>
                          this.setState({ userLab: event.currentTarget.value })
                        }
                        inputProps={{
                          name: 'userRole',
                        }}
                      >
                        {labList.map((lab) => (
                          <option key={lab} value={lab}>
                            {lab.labName}
                          </option>
                        ))}
                      </Select>
                      <div style={{ paddingBottom: '10px' }}></div>
                    </FormControl>
                  </GridCell>
                </GridRow>
              </Grid>
              {this.state.userRole !== 'Lab' ? (<Grid id="groupSelector">
                <GridCell span={5}>
                  <Grid id="lGrid" item>
                    <Paper id="lPaper" variant="outlined" n>
                      <List dense component="div" role="list">
                        {leftList != null
                          ? leftList.map((facility) => {
                            let checkname = 'check' + facility.facilityID;
                            return (
                              <ListItem role="listitem">
                                <ListItemIcon>
                                  <Checkbox
                                    name={facility.facilityID}
                                    tabIndex={-1}
                                    disableRipple
                                    checked={this.state[checkname]}
                                    value={JSON.stringify(facility)}
                                    onChange={(evt) => {
                                      this.checkLeft(evt, checkname);
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={facility.facilityName}
                                />
                              </ListItem>
                            );
                          })
                          : null}
                        <ListItem />
                      </List>
                    </Paper>
                  </Grid>
                </GridCell>
                <GridCell span={2}>
                  <div id="selectorButtons">
                    <div>
                      <Button
                        outlined
                        size="small"
                        onClick={() => {
                          this.moveRight();
                        }}
                        aria-label="move selected right"
                      >
                        &gt;
                      </Button>
                    </div>
                    <div>
                      <Button
                        outlined
                        size="small"
                        onClick={() => {
                          this.moveLeft();
                        }}
                      >
                        &lt;
                      </Button>
                    </div>
                  </div>
                </GridCell>
                <GridCell span={5}>
                  <Grid id="rGrid" item>
                    <Paper id="rPaper" variant="outlined">
                      <List dense component="div" role="list">
                        {rightList.map((facility) => {
                          let checkname = 'check' + facility.facilityID;
                          return (
                            <ListItem role="listitem">
                              <ListItemIcon>
                                <Checkbox
                                  tabIndex={-1}
                                  disableRipple
                                  value={JSON.stringify(facility)}
                                  checked={this.state[checkname]}
                                  onChange={(evt) => {
                                    this.checkRight(evt, checkname);
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText primary={facility.facilityName} />
                            </ListItem>
                          );
                        })}
                        <ListItem />
                      </List>
                    </Paper>
                  </Grid>
                </GridCell>
              </Grid>) : (null)}
            </div>
          </DialogContent>
          <DialogActions
            style={{
              borderBottom: 'solid',
              borderLeft: 'solid',
              borderRight: 'solid',
              color: 'dodgerblue',
            }}
          >
            <DialogButton
              action="update"
              unelevated
              icon="save"
              onClick={() => {
                this.setState({ currentWebUser: webUser });
                this.updateUser();
              }}
            >
              Save
            </DialogButton>
            <DialogButton
              icon="close"
              outlined
              onClick={() => {
                this.setState({ editUserDialogOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    let webUserDetails = () => {
      let facilityList = [];
      if (Object.keys(this.state.currentWebUser).length !== 0) {
        if (this.state.currentWebUser.facilities !== null) {
          this.state.currentWebUser.facilities.forEach((facility) => {
            facilityList.push(facility.facilityName);
          });
        }
      }
      return (
        <DataTable>
          <DataTableCell>
            {'Name: ' +
              this.state.currentWebUser.userFirstName +
              ' ' +
              this.state.currentWebUser.userLastName}
            {/* {'Name: ' +(this.state.currentWebUser.userFirstName === null && this.state.currentWebUser.userLastName === null ) ? '' : this.state.currentWebUser.userFirstName === null && this.state.currentWebUser.userLastName !== null ? this.state.currentWebUser.userLastName :
                    this.state.currentWebUser.userFirstName !== null && this.state.currentWebUser.userLastName === null ? this.state.currentWebUser.userFirstName : this.state.currentWebUser.userFirstName +
                      ' ' +
                      this.state.user.userLastName} */}
          </DataTableCell>
          <DataTableCell>
            {'Username: ' + this.state.currentWebUser.username}
          </DataTableCell>
          <DataTableCell>
            {'Role: ' + this.state.currentWebUser.role}
          </DataTableCell>
          <DataTableCell>{'Facilities: ' + facilityList}</DataTableCell>
          <DataTableCell>
            {'Regions: ' + this.state.currentWebUser.regions}
          </DataTableCell>
        </DataTable>
      );
    };
    let webUserData = () =>
      this.state.webUsers.map((webUser, i) => {
        return (
          <TableRow key={i}>
            <TableCell
              onClick={() => {
                this.setState(
                  { userInfoDialogOpen: true, currentWebUser: webUser },
                  () => { }
                );
              }}
              style={{
                border: '1px solid rgba(224, 224, 224, 1)',
              }}
            >
              {webUser.username}
            </TableCell>
            <TableCell>{webUser.role}</TableCell>
            <TableCell>
              <Button
                icon="edit"
                onClick={() => {
                  this.setState({
                    editUserDialogOpen: true,
                    currentWebUser: webUser,
                    username: webUser.username,
                    userFirstName: webUser.userFirstName,
                    userLastName: webUser.userLastName,
                    userRole: webUser.role,
                    userAddress: webUser.userAddress,
                    userCity: webUser.userCity,
                    userState: webUser.userState,
                    userZipcode: webUser.userZipcode,
                    userPhoneNum: webUser.userPhoneNum,
                    userGroup: webUser.groupID,
                    rightList: webUser.facilities,
                  });
                }}
              >
                Modify
              </Button>

              <Button
                icon="delete"
                onClick={() => {
                  Swal.fire({
                    title:
                      'Are you sure you want to delete User ' +
                      webUser.username +
                      ' ?',
                    text: 'You will not be able to revert this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.props.deleteUser(webUser.username);
                      Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      );
                    }
                  });
                }}
              >
                Delete
              </Button>
            </TableCell>
          </TableRow>
        );
      });
    let userInfoDialog = () => {
      let facilityList = [];
      if (Object.keys(this.state.currentWebUser).length !== 0) {
        if (this.state.currentWebUser.facilities !== null) {
          let count = 0;
          this.state.currentWebUser.facilities.map((facility) => {
            if (Object.keys(this.state.currentWebUser.facilities).length - 1 === count) {
              facilityList.push(facility.facilityName);
            }
            else {
              facilityList.push(facility.facilityName + ', ');
            }
            count++;
            return null;
          });
        }
      }
      return (
        <Dialog
          open={this.state.userInfoDialogOpen}
          onClose={(evt) => {
            this.setState({ userInfoDialogOpen: false });
          }}
          onClosed={(evt) => { }}
        >
          <DialogTitle id="addTitle">User Information</DialogTitle>
          <DialogContent>
            <Grid>
              <GridRow>
                <GridCell span={12}></GridCell>
              </GridRow>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Name: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {'Name: ' + (this.state.currentWebUser.userFirstName === null && this.state.currentWebUser.userLastName === null) ? '' : this.state.currentWebUser.userFirstName === null && this.state.currentWebUser.userLastName !== null ? this.state.currentWebUser.userLastName :
                    this.state.currentWebUser.userFirstName !== null && this.state.currentWebUser.userLastName === null ? this.state.currentWebUser.userFirstName : this.state.currentWebUser.userFirstName +
                      ' ' +
                      this.state.user.userLastName}
                </GridCell>
              </GridRow>
              <hr></hr>
              {this.state.currentWebUser.role !== 'Lab' ? (<GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Facilities: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentWebUser.facilities !== null
                    ? facilityList
                    : 'null'}
                </GridCell>
              </GridRow>) : (null)}
              <hr></hr>
              {this.state.currentWebUser.role !== 'Lab' ? (<GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Group: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentWebUser.userGroup !== null
                    ? this.state.currentWebUser.groupName
                    : 'null'}
                </GridCell>
              </GridRow>) : (<GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Lab: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentWebUser.userLab !== null
                    ? this.state.currentWebUser.userLab
                    : 'null'}
                </GridCell>
              </GridRow>)}
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Address: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentWebUser.userAddress == null
                    ? 'No Address'
                    : this.state.currentWebUser.userAddress +
                    ' ' +
                    this.state.currentWebUser.userAddress +
                    ' ' +
                    this.state.currentWebUser.userCity +
                    ' ' +
                    this.state.currentWebUser.userZipcode}
                </GridCell>
              </GridRow>
              <hr></hr>
              <GridRow>
                <GridCell span={6}>
                  <Typography id="textBold">Phone Number: </Typography>
                </GridCell>
                <GridCell span={6}>
                  {this.state.currentWebUser.userPhoneNum == null
                    ? 'No Phone Number'
                    : this.state.currentWebUser.userPhoneNum}
                </GridCell>
              </GridRow>
              <hr></hr>
            </Grid>
          </DialogContent>
          <DialogActions>
            <DialogButton
              icon="close"
              cancel
              outlined
              onClick={() => {
                this.setState({ userInfoDialogOpen: false });
              }}
            >
              Close
            </DialogButton>
          </DialogActions>
        </Dialog>
      );
    };

    return (
      <div>
        <div id="userHead">
          <div id="users">
            <Typography use="headline4"> Users </Typography>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      border: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    Username
                  </TableCell>
                  <TableCell
                    style={{
                      border: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    style={{
                      border: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{webUserData()}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <div>
          <Button
            id="addButton"
            variant="contained"
            onClick={() =>
              this.setState({
                addUserDialogOpen: true,
                username: '',
                userFirstName: '',
                userLastName: '',
                userAddress: '',
                userCity: '',
                userState: '',
                userZipcode: '',
                userPhoneNum: '',
                rightList: [],
                userRole: '',
              })
            }
          >
            Add User
          </Button>
        </div>
        {webUserInfoDialog()}
        {changeUserInfoDialog()}
        {addUserDialog()}
        {editUserDialog()}
        {userInfoDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.login.decodedJWT.role,
  webUsers: state.admin.webUsers,
  facilities: state.admin.facilities,
  groups: state.group.groups,
  labs: state.labs.labs,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsersRequest()),
    updateUser: (json) => dispatch(updateUserRequest(json)),
    addUser: (json) => dispatch(addUserRequest(json)),
    createFacilityRequest: (json) => dispatch(createFacilityRequest(json)),
    getFacilities: () => dispatch(getFacilityRequest()),
    deleteUser: (username) => dispatch(deleteUserRequest(username)),
    getGroups: () => dispatch(getGroupsRequest()),
    getLabsRequest: () => dispatch(getLabsRequest()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
